import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Sidebar from './Sidebar';
import Navbar from './Navbar/Navbar';
import Dashboard from './Dashboard/Dashboard';
import QRManagement from './QRManagement/QRManagement';
import ActiveQR from './QRManagement/QRActive';
import QRPrint from './QRManagement/QRPrint';
import NotificationPage from './Notification/NotificationPage';
import ParentAccountTable from './UserAccount/ParentAccount/ParentAccount';
import KidsProfileTable from './UserAccount/KidsProfile/KidsProfile';
import GuestUserTable from './UserAccount/GuestUser/GuestUser';
import AdminUserTable from './UserManagement/AdminUser';
import AdminUserCreate from './UserManagement/AdminUserCreate';
import AdminUserEdit from './UserManagement/AdminUserEdit';
import FeedbackTable from './Feedback/Feedback';
import SearchUserForm from './SearchUserForm';
import ProfileUser from './Profile/ProfileUser';
import ProfileUserEdit from './Profile/ProfileUserEdit';
import ProfileUserPassword from './Profile/ProfileUserPassword';
import ProductListTable from './Product/ProductListTable';
import ProductCreate from './Product/ProductAdd';
import ProductEdit from './Product/ProductEdit';
import KidsProfileEdit from './UserAccount/KidsProfile/KidsProfileEdit';
import ParentAccountEdit from './UserAccount/ParentAccount/ParentAccountEdit';
import VoiceTable from './Voice/VoiceTable';
import VoiceUpload from './Voice/VoiceUpload';

const AdminPage = () => {
  return (
    <div className="flex h-screen">
      <Sidebar className="w-2/5" />
      <div className="flex flex-col flex-grow w-1/5">
        <Navbar />
        <div className="flex-grow bg-[#f9fafb] p-4 overflow-auto">
          <Routes>
            <Route path="dashboard" element={<Dashboard />} />

            <Route path="parent-accounts" element={<ParentAccountTable />} />
            <Route path="parent-accounts/edit" element={<ParentAccountEdit />} />

            <Route path="kids-profiles" element={<KidsProfileTable />} />
            <Route path="kids-profiles/edit" element={<KidsProfileEdit />} />


            <Route path="guest-users" element={<GuestUserTable />} />

            <Route path="qr-management" element={<QRManagement />} />
            <Route path="qr-active" element={<ActiveQR />} />
            <Route path="qr-print" element={<QRPrint />} />

            <Route path="admin-users" element={<AdminUserTable />}/>
            <Route path="admin-users/create-admin" element={<AdminUserCreate />}/>
            <Route path="admin-users/edit-admin" element={<AdminUserEdit />}/>

            <Route path="notifications" element={<NotificationPage />} />
            <Route path="feedbacks" element={<FeedbackTable/>}/>

            <Route path='search-user' element={<SearchUserForm/>} />

            <Route path='user-profile' element={<ProfileUser/>} />
            <Route path='user-profile/user-profile-edit' element={<ProfileUserEdit/>} />
            <Route path='user-profile/user-password-change' element={<ProfileUserPassword/>} />

            <Route path='product-list' element={<ProductListTable/>} />
            <Route path='product-list/product-add' element={<ProductCreate/>} />
            <Route path='product-list/product-edit' element={<ProductEdit/>} />

            <Route path='voice-list' element={<VoiceTable/>} />
            <Route path='voice-list/upload' element={<VoiceUpload/>} />

          </Routes>
        </div>
      </div>
    </div>
  );
}

export default AdminPage;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Undo2 } from 'lucide-react';

const ProfileUserPassword = () => {
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  });

  const [error, setError] = useState('')

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate passwords match
    if (formData.newPassword !== formData.confirmNewPassword) {
      setError('New passwords do not match');
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/change-password`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          oldPassword: formData.oldPassword,
          newPassword: formData.newPassword
        })
      });
  
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message);
      }
  
      // Success 
      navigate(-1);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <>
      <div className="flex justify-between items-center mb-2 ml-4">
        <h1 className="text-2xl font-bold">Change Password</h1>
        <div className="flex space-x-2">
          <button 
            className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 mr-5"
            onClick={handleBack}
          >
            <Undo2 size={20} />
          </button>
        </div>
      </div>
      <p className="text-gray-500 text-sm ml-4">
        User Profile <span className="text-gray-400">&gt; Change Password</span>
      </p>
      <div className="bg-white rounded-2xl shadow-md p-6 m-5">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-3 gap-4">
            <div className="flex flex-col">
              <label className="text-gray-700">Old Password</label>
              <input 
                type="password" 
                name="oldPassword" 
                value={formData.oldPassword} 
                onChange={handleChange} 
                placeholder="Enter Old Password"
                className="border rounded px-2 py-1 bg-gray-100"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700">New Password</label>
              <input 
                type="password" 
                name="newPassword" 
                value={formData.newPassword} 
                onChange={handleChange} 
                placeholder="Enter New Password"
                className="border rounded px-2 py-1 bg-gray-100"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700">Confirm New Password</label>
              <input 
                type="password" 
                name="confirmNewPassword" 
                value={formData.confirmNewPassword} 
                onChange={handleChange} 
                placeholder="Confirm New Password"
                className="border rounded px-2 py-1 bg-gray-100"
              />
            </div>
          </div>
          <div className="flex justify-end">
            <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ProfileUserPassword;
import React, { useState, useEffect } from 'react';
import { Eye, Edit, Trash2 } from 'lucide-react';
import KidsProfileModal from './KidsProfileModal';
import { useNavigate } from 'react-router-dom';

const KidsProfileTable = () => {
  const [kids, setKids] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedKid, setSelectedKid] = useState(null);
  const [filterStatus, setFilterStatus] = useState({
    all: true,
    completed: false,
    inProgress: false,
  });
  const [searchQuery, setSearchQuery] = useState('');

  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [kidToDelete, setKidToDelete] = useState(null);

  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    fetchKids();
  }, []);

  const fetchKids = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/view-kids`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch kids profiles');
      }

      const data = await response.json();
      setKids(data);
      setError(null);
    } catch (err) {
      setError(err.message || 'Failed to fetch kids data');
      console.error('Error fetching kids:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredData = kids.filter((kid) => {
    const matchesSearchQuery = 
      (kid.name?.toLowerCase().includes(searchQuery.toLowerCase()) || false) ||
      (kid.parent?.phone?.toLowerCase().includes(searchQuery.toLowerCase()) || false);

    if (!matchesSearchQuery) return false;

    if (filterStatus.all) return true;
    const progressStatus = kid.progress === 100 ? 'Completed' : 'In Progress';
    if (filterStatus.completed && progressStatus === 'Completed') return true;
    if (filterStatus.inProgress && progressStatus === 'In Progress') return true;
    return false;
  });

  const handleOpenModal = (kid) => {
    const formattedKid = {
      name: kid.name,
      age: kid.age,
      gender: kid.gender,
      voiceFile: kid.voiceFile,
      parent: kid.parent,
      lastActivity: kid.lastActivity ? new Date(kid.lastActivity).toLocaleString() : 'N/A',
      lastLesson: kid.lastLesson || 'N/A',
      progress: `${kid.progress || 0}%`,
      voiceUploader: kid.voiceUploader
    };
    
    setSelectedKid(formattedKid);
    setIsModalOpen(true);
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const sortedData = [...filteredData].sort((a, b) => {
    if (!sortField) return 0;
  
    let aValue = a[sortField];
    let bValue = b[sortField];
  
    // Special handling for dates and numbers
    if (sortField === 'lastActivity') {
      aValue = new Date(aValue || 0).getTime();
      bValue = new Date(bValue || 0).getTime();
    } else if (sortField === 'age' || sortField === 'progress') {
      aValue = Number(aValue) || 0;
      bValue = Number(bValue) || 0;
    }
  
    if (sortDirection === 'asc') {
      return aValue > bValue ? 1 : -1;
    } else {
      return aValue < bValue ? 1 : -1;
    }
  });

  

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedKid(null);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleEdit = (kid) => {
    navigate('/admin/kids-profiles/edit', {
      state: {
        id: kid.id,
        name: kid.name,
        age: kid.age,
        gender: kid.gender
      }
    });
  };

  if (isLoading) {
    return <div className="text-center p-4">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center p-4">{error}</div>;
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(Math.min(Math.max(1, pageNumber), totalPages));
  };
  
  const handleLimitChange = (event) => {
    const newLimit = parseInt(event.target.value);
    setItemsPerPage(newLimit);
    setCurrentPage(1);
  };

  const handleDeleteClick = (kid) => {
    setKidToDelete(kid);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/delete-child/${kidToDelete.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete child profile');
      }
  
      setKids(kids.filter(kid => kid.id !== kidToDelete.id));
      setShowDeleteModal(false);
      setKidToDelete(null);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <>
      <div className="flex justify-between items-center mb-2 ml-4">
        <h1 className="text-2xl font-bold">Kid's Profile</h1>
      </div>
      <p className="text-gray-500 text-sm ml-4">
        User Account <span className="text-gray-400">&gt; Kid's Profile</span>
      </p>
      <div className="bg-white rounded-2xl shadow-md p-6 m-5">
        {/* Header */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-gray-700">Kids Profile</h2>
          <div className="flex items-center space-x-2">
            <input 
              type="text" 
              placeholder="Search..." 
              value={searchQuery}
              onChange={handleSearchChange}
              className="border rounded px-2 py-1"
            />
            <button className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600">
              Filter
            </button>
          </div>
        </div>

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto">
            {/* Table Head */}
            <thead>
              <tr className="bg-blue-100 text-blue-600">
                <th className="px-4 py-2 text-left">#</th>
                <th 
                  className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
                  onClick={() => handleSort('name')}
                >
                  Name {sortField === 'name' && (sortDirection === 'asc' ? '↑' : '↓')}
                </th>
                <th 
                  className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
                  onClick={() => handleSort('age')}
                >
                  Age {sortField === 'age' && (sortDirection === 'asc' ? '↑' : '↓')}
                </th>
                <th className="px-4 py-2 text-left">Gender</th>
                <th className="px-4 py-2 text-left">Voice File</th>
                <th className="px-4 py-2 text-left">Parent Account</th>
                <th 
                  className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
                  onClick={() => handleSort('lastActivity')}
                >
                  Last Activity {sortField === 'lastActivity' && (sortDirection === 'asc' ? '↑' : '↓')}
                </th>
                <th className="px-4 py-2 text-left">Lesson Status</th>
                <th 
                  className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
                  onClick={() => handleSort('progress')}
                >
                  Progress {sortField === 'progress' && (sortDirection === 'asc' ? '↑' : '↓')}
                </th>
                <th className="px-4 py-2 text-left">Action</th>
              </tr>
            </thead>

            {/* Table Body */}
            <tbody className="text-gray-600">
              {currentItems.map((kid, index) => (
                <tr key={kid.id} className="border-b">
                  <td className="px-4 py-2">{indexOfFirstItem + index + 1}</td>
                  <td className="px-4 py-2">{kid.name}</td>
                  <td className="px-4 py-2">{kid.age}</td>
                  <td className="px-4 py-2">{kid.gender}</td>
                  <td className="px-4 py-2">{kid.voiceFile ? 'Uploaded' : 'Not Uploaded'}</td>
                  <td className="px-4 py-2">{kid.parent.phone || kid.parent.email}</td>
                  <td className="px-4 py-2">{kid.lastActivity ? new Date(kid.lastActivity).toLocaleString() : 'N/A'}</td>
                  <td className="px-4 py-2">
                    <span className={`px-2 py-1 rounded-full text-xs ${
                      kid.progress === 100 ? 'bg-blue-100 text-blue-800' : 'bg-green-100 text-green-800'
                    }`}>
                      {kid.lastLesson}
                    </span>
                  </td>
                  <td className="px-4 py-2">{`${kid.progress || 0}%`}</td>
                  <td className="p-2">
                    <div className="flex space-x-2">
                      <Eye 
                        size={16} 
                        className="text-blue-500 cursor-pointer" 
                        onClick={() => handleOpenModal(kid)} 
                      />
                      <Edit 
                        size={16} 
                        className="text-green-500 cursor-pointer" 
                        onClick={() => handleEdit(kid)} 
                      />
                      <Trash2 
                        size={16} 
                        className="text-red-500 cursor-pointer"
                        onClick={() => handleDeleteClick(kid)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination and Sorting */}
          <div className="flex justify-between items-center mt-4">
            <div className="flex items-center space-x-2">
              <span>Show:</span>
              <select 
                className="border rounded px-2 py-1"
                value={itemsPerPage}
                onChange={handleLimitChange}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={filteredData.length}>All</option>
              </select>
              <span>entries</span>
            </div>

            <div className="flex items-center space-x-2">
              <button 
                className="border rounded px-3 py-1 hover:bg-gray-100"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                &lt;
              </button>

              {[...Array(totalPages)].map((_, index) => (
                <button
                  key={index + 1}
                  className={`px-3 py-1 rounded ${
                    currentPage === index + 1 
                      ? 'bg-blue-500 text-white' 
                      : 'border hover:bg-gray-100'
                  }`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              ))}

              <button 
                className="border rounded px-3 py-1 hover:bg-gray-100"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                &gt;
              </button>
            </div>

            <div className="text-gray-600">
              Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, filteredData.length)} of {filteredData.length} entries
            </div>
          </div>
        </div>
        {isModalOpen && <KidsProfileModal kid={selectedKid} onClose={handleCloseModal} />}
        {showDeleteModal && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <div className="bg-white p-6 rounded-lg shadow-xl">
                  <h3 className="text-lg font-semibold mb-4">Confirm Delete</h3>
                  <p className="mb-6">Are you sure you want to delete profile: {kidToDelete?.name}?</p>
                  <div className="flex justify-end space-x-3">
                    <button 
                      className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
                      onClick={() => {
                        setShowDeleteModal(false);
                        setKidToDelete(null);
                      }}
                    >
                      Cancel
                    </button>
                    <button 
                      className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                      onClick={handleDeleteConfirm}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            )}
      </div>
    </>
  );
};

export default KidsProfileTable;
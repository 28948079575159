import React, { useState, useEffect } from 'react';

const StatsBoxes = () => {
  const [stats, setStats] = useState({
    users: { Premium: 0, Register: 0, Guest: 0 },
    newUsers: { Premium: 0, Register: 0, Guest: 0 },
    kidsProfile: { Total: 0, Boy: 0, Girl: 0 },
    newKidsProfile: { Total: 0, Boy: 0, Girl: 0 }
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAllStats = async () => {
      try {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        };

        const [usersRes, newUsersRes, kidsRes, newKidsRes] = await Promise.all([
          fetch(`${process.env.REACT_APP_API_URL}/api/admin/dashboard/get-user-stat`, { headers }),
          fetch(`${process.env.REACT_APP_API_URL}/api/admin/dashboard/get-new-user-stat`, { headers }),
          fetch(`${process.env.REACT_APP_API_URL}/api/admin/dashboard/get-kids-stat`, { headers }),
          fetch(`${process.env.REACT_APP_API_URL}/api/admin/dashboard/get-new-kids-stat`, { headers })
        ]);

        if (!usersRes.ok || !newUsersRes.ok || !kidsRes.ok || !newKidsRes.ok) {
          throw new Error('One or more requests failed');
        }

        const [users, newUsers, kidsProfile, newKidsProfile] = await Promise.all([
          usersRes.json(),
          newUsersRes.json(),
          kidsRes.json(),
          newKidsRes.json()
        ]);

        setStats({
          users: {
            Premium: users.Premium,
            Register: users.Registered,
            Guest: users.Guest
          },
          newUsers: {
            Premium: newUsers.Premium,
            Register: newUsers.Registered,
            Guest: newUsers.Guest
          },
          kidsProfile: {
            Total: kidsProfile.Total,
            Boy: kidsProfile.Boy,
            Girl: kidsProfile.Girl
          },
          newKidsProfile: {
            Total: newKidsProfile.Total,
            Boy: newKidsProfile.Boy,
            Girl: newKidsProfile.Girl
          }
        });
      } catch (err) {
        setError('Failed to fetch statistics');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchAllStats();
  }, []);

  if (loading) return <div className="text-center">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mx-5">
      {/* Users Box */}
      <div className="bg-white rounded-2xl shadow-md p-4">
        <h3 className="text-lg font-semibold text-gray-700 mb-2">Users</h3>
        <div className="text-gray-600">
          <p className="mb-2 flex justify-between"><span>Premium:</span><span className="text-right mr-2">{stats.users.Premium}</span></p>
          <p className="mb-2 flex justify-between"><span>Register:</span><span className="text-right mr-2">{stats.users.Register}</span></p>
          <p className="mb-2 flex justify-between"><span>Guest:</span><span className="text-right mr-2">{stats.users.Guest}</span></p>
        </div>
      </div>

      {/* New Users Box */}
      <div className="bg-white rounded-2xl shadow-md p-4">
        <h3 className="text-lg font-semibold text-gray-700 mb-2">New Users</h3>
        <div className="text-gray-600">
          <p className="mb-2 flex justify-between"><span>Premium:</span><span className="text-right mr-2">{stats.newUsers.Premium}</span></p>
          <p className="mb-2 flex justify-between"><span>Register:</span><span className="text-right mr-2">{stats.newUsers.Register}</span></p>
          <p className="mb-2 flex justify-between"><span>Guest:</span><span className="text-right mr-2">{stats.newUsers.Guest}</span></p>
        </div>
      </div>

      {/* Kids Profile Box */}
      <div className="bg-white rounded-2xl shadow-md p-4">
        <h3 className="text-lg font-semibold text-gray-700 mb-2">Kids Profile</h3>
        <div className="text-gray-600">
          <p className="mb-2 flex justify-between"><span>Total:</span><span className="text-right mr-2">{stats.kidsProfile.Total}</span></p>
          <p className="mb-2 flex justify-between"><span>Boys:</span><span className="text-right mr-2">{stats.kidsProfile.Boy}</span></p>
          <p className="mb-2 flex justify-between"><span>Girls:</span><span className="text-right mr-2">{stats.kidsProfile.Girl}</span></p>
        </div>
      </div>

      {/* New Kids Profile Box */}
      <div className="bg-white rounded-2xl shadow-md p-4">
        <h3 className="text-lg font-semibold text-gray-700 mb-2">New Kids Profile</h3>
        <div className="text-gray-600">
          <p className="mb-2 flex justify-between"><span>Total:</span><span className="text-right mr-2">{stats.newKidsProfile.Total}</span></p>
          <p className="mb-2 flex justify-between"><span>Boys:</span><span className="text-right mr-2">{stats.newKidsProfile.Boy}</span></p>
          <p className="mb-2 flex justify-between"><span>Girls:</span><span className="text-right mr-2">{stats.newKidsProfile.Girl}</span></p>
        </div>
      </div>
    </div>
  );
};

export default StatsBoxes;
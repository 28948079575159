import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Undo2 } from 'lucide-react';
import ImageDropzone from './ImageDropZone';

const ProductEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const productData = location.state;
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    productName: productData?.productName || '',
    productType: productData?.productType || 'Book',
    productPrice: productData?.productPrice || '',
    productLogo: null,
    coverImage: null,
    about: productData?.about || ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setError(null);
  };

  const handleDrop = (acceptedFiles, fieldName) => {
    setFormData(prev => ({ ...prev, [fieldName]: acceptedFiles[0] }));
    setError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      const formDataToSend = new FormData();
      formDataToSend.append('productName', formData.productName);
      formDataToSend.append('productType', formData.productType);
      formDataToSend.append('productPrice', formData.productPrice);
      formDataToSend.append('about', formData.about);

      if (formData.productLogo) {
        formDataToSend.append('productLogo', formData.productLogo);
      }
      if (formData.coverImage) {
        formDataToSend.append('productCover', formData.coverImage);
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/product/${productData.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: formDataToSend
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to update product');
      }

      navigate(-1);
    } catch (err) {
      setError(err.message);
      console.error('Error updating product:', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBack = () => navigate(-1);

  return (
    <>
<div className="flex justify-between items-center mb-2 ml-4">
        <h1 className="text-2xl font-bold">Edit Product</h1>
        <button 
          className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 mr-5"
          onClick={handleBack}
        >
          <Undo2 size={20} />
        </button>
      </div>
      <p className="text-gray-500 text-sm ml-4">
        Product Management <span className="text-gray-400">&gt; Edit Product</span>
      </p>
      <div className="bg-white rounded-2xl shadow-md p-6 m-5">
        {error && (
          <div className="mb-4 p-3 bg-red-100 text-red-600 rounded-lg">
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-3 gap-4">
            <div className="flex flex-col">
              <label className="text-gray-700">Product Name</label>
              <input 
                type="text" 
                name="productName" 
                value={formData.productName} 
                onChange={handleChange} 
                placeholder="Enter Product Name"
                className="border rounded px-2 py-1"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700">Product Type</label>
              <select 
                name="productType" 
                value={formData.productType} 
                onChange={handleChange} 
                className="border rounded px-2 py-1"
              >
                <option value="Book">Book</option>
                <option value="Card">Card</option>
              </select>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700">Product Price</label>
              <input 
                type="number" 
                name="productPrice" 
                value={formData.productPrice} 
                onChange={handleChange} 
                placeholder="Enter Product Price"
                className="border rounded px-2 py-1"
              />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4">
            <ImageDropzone
              fieldName="productLogo"
              label="Product Logo"
              onDrop={handleDrop}
              file={formData.productLogo}
            />
            <ImageDropzone
              fieldName="coverImage"
              label="Cover Image"
              onDrop={handleDrop}
              file={formData.coverImage}
            />
            <div className="flex flex-col">
              <label className="text-gray-700">About</label>
              <input 
                type="text" 
                name="about" 
                value={formData.about} 
                onChange={handleChange} 
                placeholder="Enter About Information"
                className="border rounded px-2 py-1"
              />
            </div>
            </div>
          <div className="flex justify-end">
            <button 
              type="submit" 
              className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 disabled:bg-blue-300"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ProductEdit;
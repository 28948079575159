import React, { useState } from 'react';

const ProductStatsBoxes = ({ products }) => {
  const [imageErrors, setImageErrors] = useState({});

  const handleImageError = (productId) => {
    setImageErrors(prev => ({
      ...prev,
      [productId]: true
    }));
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mx-5 mb-6">
      {products.map((product) => (
        <div key={product._id} className="bg-white rounded-2xl shadow-md p-4">
          <div className="flex">
            {!imageErrors[product._id] ? (
              <img 
                src={product.productCover} 
                alt={product.productName} 
                className="w-16 h-16 rounded-lg object-cover mr-4"
                onError={() => handleImageError(product._id)}
              />
            ) : (
              <div className="w-16 h-16 bg-gray-200 rounded-lg mr-4 flex items-center justify-center">
                <span className="text-gray-400 text-xs">No Image</span>
              </div>
            )}
            <div className="flex flex-col justify-between">
              <h3 className="text-lg font-semibold text-gray-700">{product.productName}</h3>
              <p className="text-gray-600"><strong>Price:</strong> {product.productPrice}</p>
              <p className="text-gray-600"><strong>Type:</strong> {product.productType}</p>
              <p className="text-gray-600"><strong>Total Page:</strong> {product.totalPage}</p>
              <p className="text-gray-600"><strong>Total Sales:</strong> {product.totalSales || 0}</p>
            </div>
          </div>
          <p className="text-gray-600 mt-4 line-clamp-2"><strong>About:</strong> {product.about}</p>
        </div>
      ))}
    </div>
  );
};

export default ProductStatsBoxes;
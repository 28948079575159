import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Undo2 } from 'lucide-react';

const ProductAdd = () => {
  const [formData, setFormData] = useState({
    productName: '',
    productType: '',
    productPrice: '',
    totalPage: '',
    about: '',
    productLogo: null,
    productCover: null,
    launchDate: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files[0]) {
      if (files[0].size > 5 * 1024 * 1024) { // 5MB limit
        setError(`${name} file size should be less than 5MB`);
        return;
      }
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
      if (!allowedTypes.includes(files[0].type)) {
        setError(`${name} must be a JPEG, PNG, or GIF file`);
        return;
      }
      setFormData({ ...formData, [name]: files[0] });
      setError(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const formDataToSend = new FormData();
      
      // Append all form fields
      Object.keys(formData).forEach(key => {
        if (formData[key] !== null && formData[key] !== '') {
          formDataToSend.append(key, formData[key]);
        }
      });

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/create-product`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: formDataToSend
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to create product');
      }

      
      navigate('/admin/product-list');

    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="flex justify-between items-center mb-2 ml-4">
        <h1 className="text-2xl font-bold">Add New Product</h1>
        <div className="flex space-x-2">
          <button 
            className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 mr-5"
            onClick={handleBack}
          >
            <Undo2 size={20} />
          </button>
        </div>
      </div>
      <p className="text-gray-500 text-sm ml-4">
        Product Management <span className="text-gray-400">&gt; Add New Product</span>
      </p>
      <div className="bg-white rounded-2xl shadow-md p-6 m-5">
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-3 gap-4">
            <div className="flex flex-col">
              <label className="text-gray-700">Product Name</label>
              <input 
                type="text" 
                name="productName" 
                value={formData.productName} 
                onChange={handleChange} 
                placeholder="Enter Product Name"
                className="border rounded px-2 py-1"
                required
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700">Product Type</label>
              <select 
                name="productType" 
                value={formData.productType} 
                onChange={handleChange}
                className="border rounded px-2 py-1"
                required
              >
                <option value="">Select Type</option>
                <option value="Book">Book</option>
                <option value="Card">Card</option>
              </select>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700">Product Price</label>
              <input 
                type="number" 
                name="productPrice" 
                value={formData.productPrice} 
                onChange={handleChange} 
                placeholder="Enter Price"
                className="border rounded px-2 py-1"
                required
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700">Total Pages</label>
              <input 
                type="number" 
                name="totalPage" 
                value={formData.totalPage} 
                onChange={handleChange} 
                placeholder="Enter Total Pages"
                className="border rounded px-2 py-1"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700">Launch Date</label>
              <input 
                type="date" 
                name="launchDate" 
                value={formData.launchDate} 
                onChange={handleChange}
                className="border rounded px-2 py-1"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700">Product Logo</label>
              <input 
                type="file" 
                name="productLogo" 
                onChange={handleFileChange} 
                accept="image/*"
                className="border rounded px-2 py-1"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700">Cover Image</label>
              <input 
                type="file" 
                name="productCover" 
                onChange={handleFileChange} 
                accept="image/*"
                className="border rounded px-2 py-1"
              />
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-gray-700">About</label>
            <textarea 
              name="about" 
              value={formData.about} 
              onChange={handleChange} 
              placeholder="Enter Product Description"
              className="border rounded px-2 py-1 h-32"
            />
          </div>
          <div className="flex justify-end">
            <button 
              type="submit" 
              className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 disabled:bg-blue-300"
              disabled={loading}
            >
              {loading ? 'Creating...' : 'Submit'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ProductAdd;
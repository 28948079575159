import React, { useState, useEffect } from 'react';
import { Eye, Edit, Trash2 } from 'lucide-react';
import ParentAccountModal from './ParentAccountModal';
import { useNavigate } from 'react-router-dom';

const ParentAccountTable = () => {
  const navigate = useNavigate();
  const [parents, setParents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedParent, setSelectedParent] = useState(null);
  const [filterStatus, setFilterStatus] = useState({
    all: true,
    premium: false,
    register: false,
  });
  const [searchQuery, setSearchQuery] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [parentToDelete, setParentToDelete] = useState(null);

  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    fetchParents();
  }, []);

  const fetchParents = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/view-parents`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch parents');
      }

      const data = await response.json();
      setParents(data);
      setError(null);
    } catch (err) {
      setError(err.message || 'Failed to fetch parents data');
      console.error('Error fetching parents:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredData = parents.filter((row) => {
    const matchesSearchQuery = 
      (row.name?.toLowerCase().includes(searchQuery.toLowerCase()) || false) ||
      (row.email?.toLowerCase().includes(searchQuery.toLowerCase()) || false) ||
      (row.createdAt?.toLowerCase().includes(searchQuery.toLowerCase()) || false);

    if (!matchesSearchQuery) return false;

    if (filterStatus.all) return true;
    if (filterStatus.premium && row.status === 'Premium') return true;
    if (filterStatus.register && row.status === 'Registered') return true;
    return false;
  });

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  
  const handleCheckboxChange = (e) => {
    const { name } = e.target;
    if (name === 'all') {
      setFilterStatus({
        all: true,
        premium: false,
        register: false,
      });
    } else {
      setFilterStatus({
        ...filterStatus,
        all: false,
        [name]: !filterStatus[name],
      });
    }
  };
  
  const handleOpenModal = async (parent) => {
    try {
      // First get children details for this parent
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/parent/${parent.id}/children`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch children details');
      }
  
      const childrenData = await response.json();
  
      const formattedParent = {
        name: parent.name || 'N/A',
        email: parent.email || 'N/A',
        mobileNumber: parent.phoneNo || 'N/A',
        status: parent.status, // Already formatted as type in getAllParents
        creationDate: new Date(parent.createdAt).toLocaleString(),
        lastLogin: parent.lastLogin || 'Never logged in',
        childrenDetails: {
          kidsName: childrenData.children?.length 
            ? childrenData.children.map(child => child.name).join(', ') 
            : 'No children',
          lastActivity: childrenData.children?.length 
            ? childrenData.children.map(child => 
                child.last_activity ? new Date(child.last_activity).toLocaleString() : 'N/A'
              ).join(', ') 
            : 'N/A',
          lesson: childrenData.children?.length 
            ? childrenData.children.map(child => child.lastLevel || 'N/A').join(', ') 
            : 'N/A',
          progress: childrenData.children?.length 
            ? childrenData.children.map(child => `${child.progress || 0}%`).join(', ') 
            : 'N/A'
        }
      };
  
      setSelectedParent(formattedParent);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching children details:', error);
      // Still show modal with parent info but without children
      const formattedParent = {
        name: parent.name || 'N/A',
        email: parent.email || 'N/A',
        mobileNumber: parent.phoneNo || 'N/A',
        status: parent.status,
        creationDate: new Date(parent.createdAt).toLocaleString(),
        lastLogin: parent.lastLogin || 'Never logged in',
        childrenDetails: {
          kidsName: 'Error loading children data',
          lastActivity: 'N/A',
          lesson: 'N/A',
          progress: 'N/A'
        }
      };
      setSelectedParent(formattedParent);
      setIsModalOpen(true);
    }
  };
  
  const handleCloseModal = () => {
    setSelectedParent(null);
    setIsModalOpen(false);
  };

  const handleEdit = (parent) => {
    navigate('/admin/parent-accounts/edit', {
      state: {
        id: parent.id,
        name: parent.name,
        email: parent.email,
        phoneNo: parent.phoneNo,
        bookCode: parent.bookCode,
        type: parent.status,
        isActive: parent.isActive
      }
    });
  };

  if (isLoading) {
    return <div className="text-center p-4">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center p-4">{error}</div>;
  }

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const sortedData = [...filteredData].sort((a, b) => {
    if (!sortField) return 0;
  
    let aValue = a[sortField];
    let bValue = b[sortField];
  
    // Special handling for dates
    if (sortField === 'createdAt' || sortField === 'lastLogin') {
      aValue = new Date(aValue || 0).getTime();
      bValue = new Date(bValue || 0).getTime();
    }
  
    if (sortDirection === 'asc') {
      return aValue > bValue ? 1 : -1;
    } else {
      return aValue < bValue ? 1 : -1;
    }
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(Math.min(Math.max(1, pageNumber), totalPages));
  };
  
  const handleLimitChange = (event) => {
    const newLimit = parseInt(event.target.value);
    setItemsPerPage(newLimit);
    setCurrentPage(1);
  };

  const handleDeleteClick = (parent) => {
    setParentToDelete(parent);
    setShowDeleteModal(true);
  };
  
  const handleDeleteConfirm = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/delete-parent/${parentToDelete.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete parent');
      }
  
      setParents(parents.filter(parent => parent.id !== parentToDelete.id));
      setShowDeleteModal(false);
      setParentToDelete(null);
    } catch (error) {
      setError(error.message);
    }
  };

  

  

  return (
    <>
      <div className="flex justify-between items-center mb-2 ml-4">
        <h1 className="text-2xl font-bold">Parent Account</h1>
      </div>
      <p className="text-gray-500 text-sm ml-4">
        User Account <span className="text-gray-400">&gt; Parent Account</span>
      </p>
      <div className="bg-white rounded-2xl shadow-md p-6 m-5">
        {/* Header */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-gray-700">Parent Account</h2>
          <div className="flex items-center space-x-2">
            <input 
              type="text" 
              placeholder="Search..." 
              value={searchQuery}
              onChange={handleSearchChange}
              className="border rounded px-2 py-1"
            />
            <button className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600">
              Filter
            </button>
          </div>
        </div>

        {/* Filters */}
        <div className="flex space-x-4 mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              name="all"
              checked={filterStatus.all}
              onChange={handleCheckboxChange}
              className="mr-2"
            />
            All
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="premium"
              checked={filterStatus.premium}
              onChange={handleCheckboxChange}
              className="mr-2"
            />
            Premium
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="register"
              checked={filterStatus.register}
              onChange={handleCheckboxChange}
              className="mr-2"
            />
            Register
          </label>
        </div>

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto">
            {/* Table Head */}
            <thead>
              <tr className="bg-blue-100 text-blue-600">
                <th className="px-4 py-2 text-left">#</th>
                <th 
                  className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
                  onClick={() => handleSort('name')}
                >
                  Name {sortField === 'name' && (sortDirection === 'asc' ? '↑' : '↓')}
                </th>
                <th 
                  className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
                  onClick={() => handleSort('email')}
                >
                  Email {sortField === 'email' && (sortDirection === 'asc' ? '↑' : '↓')}
                </th>
                <th 
                  className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
                  onClick={() => handleSort('phoneNo')}
                >
                  Phone Number {sortField === 'phoneNo' && (sortDirection === 'asc' ? '↑' : '↓')}
                </th>
                <th 
                  className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
                  onClick={() => handleSort('createdAt')}
                >
                  Creation Date {sortField === 'createdAt' && (sortDirection === 'asc' ? '↑' : '↓')}
                </th>
                <th 
                  className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
                  onClick={() => handleSort('lastLogin')}
                >
                  Last Login {sortField === 'lastLogin' && (sortDirection === 'asc' ? '↑' : '↓')}
                </th>
                <th className="px-4 py-2 text-left">QR Activation</th>
                <th 
                  className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
                  onClick={() => handleSort('noOfChildren')}
                >
                  Child {sortField === 'noOfChildren' && (sortDirection === 'asc' ? '↑' : '↓')}
                </th>
                <th className="px-4 py-2 text-left">Status</th>
                <th className="px-4 py-2 text-left">Action</th>
              </tr>
            </thead>

            {/* Table Body */}
            <tbody className="text-gray-600">
              {currentItems.map((row, index) => (
                <tr key={row.id} className="border-b">
                  <td className="px-4 py-2">{indexOfFirstItem + index + 1}</td>
                  <td className="px-4 py-2">{row.name}</td>
                  <td className="px-4 py-2">{row.email}</td>
                  <td className="px-4 py-2">{row.phoneNo}</td>
                  <td className="px-4 py-2">{new Date(row.createdAt).toLocaleString()}</td>
                  <td className="px-4 py-2">{row.lastLogin}</td>
                  <td className="px-4 py-2">{row.qrActivation}</td>
                  <td className="px-4 py-2">{row.noOfChildren}</td>
                  <td className="px-4 py-2">
                    <span className={`px-2 py-1 rounded-full text-xs ${
                      row.status === 'Premium' ? 'bg-blue-100 text-blue-800' :
                      row.status === 'Registered' ? 'bg-green-100 text-green-800' :
                      'bg-gray-100 text-gray-800'
                    }`}>
                      {row.status}
                    </span>
                  </td>
                  <td className="p-2">
                    <div className="flex space-x-2">
                      <Eye 
                        size={16} 
                        className="text-blue-500 cursor-pointer" 
                        onClick={() => handleOpenModal(row)} 
                      />
                      <Edit 
                        size={16} 
                        className="text-green-500 cursor-pointer" 
                        onClick={() => handleEdit(row)}
                      />
                      <Trash2 
                        size={16} 
                        className="text-red-500 cursor-pointer"
                        onClick={() => handleDeleteClick(row)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination and Sorting */}
          <div className="flex justify-between items-center mt-4">
            <div className="flex items-center space-x-2">
              <span>Show:</span>
              <select 
                className="border rounded px-2 py-1"
                value={itemsPerPage}
                onChange={handleLimitChange}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={filteredData.length}>All</option>
              </select>
              <span>entries</span>
            </div>

            <div className="flex items-center space-x-2">
              <button 
                className="border rounded px-3 py-1 hover:bg-gray-100"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                &lt;
              </button>

              {[...Array(totalPages)].map((_, index) => (
                <button
                  key={index + 1}
                  className={`px-3 py-1 rounded ${
                    currentPage === index + 1 
                      ? 'bg-blue-500 text-white' 
                      : 'border hover:bg-gray-100'
                  }`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              ))}

              <button 
                className="border rounded px-3 py-1 hover:bg-gray-100"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                &gt;
              </button>
            </div>

            <div className="text-gray-600">
              Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, filteredData.length)} of {filteredData.length} entries
            </div>
          </div>
        </div>
        {isModalOpen && <ParentAccountModal parent={selectedParent} onClose={handleCloseModal} />}
        {showDeleteModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
    <div className="bg-white p-6 rounded-lg shadow-xl">
      <h3 className="text-lg font-semibold mb-4">Confirm Delete</h3>
      <p className="mb-6">Are you sure you want to delete parent: {parentToDelete?.name}? This will also delete all associated children accounts.</p>
      <div className="flex justify-end space-x-3">
        <button 
          className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
          onClick={() => {
            setShowDeleteModal(false);
            setParentToDelete(null);
          }}
        >
          Cancel
        </button>
        <button 
          className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          onClick={handleDeleteConfirm}
        >
          Delete
        </button>
      </div>
    </div>
  </div>
)}
      </div>
    </>
  );
};

export default ParentAccountTable;

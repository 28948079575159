import React, { useState, useEffect } from 'react';
import { Eye, Edit, CloudUpload } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import KidsProfileModal from './../UserAccount/KidsProfile/KidsProfileModal';

const VoiceTable = () => {
  const navigate = useNavigate();
  const [stats, setStats] = useState({
    kidsProfile: { Total: 0, Boy: 0, Girl: 0 }
  });
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedKid, setSelectedKid] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        };

        const [statsRes, profilesRes] = await Promise.all([
          fetch(`${process.env.REACT_APP_API_URL}/api/admin/dashboard/get-kids-stat`, { headers }),
          fetch(`${process.env.REACT_APP_API_URL}/api/admin/view-kids`, { headers })
        ]);

        if (!statsRes.ok || !profilesRes.ok) {
          throw new Error('Failed to fetch data');
        }

        const [statsData, profilesData] = await Promise.all([
          statsRes.json(),
          profilesRes.json()
        ]);

        setStats({
          kidsProfile: {
            Total: statsData.Total,
            Boy: statsData.Boy,
            Girl: statsData.Girl
          }
        });
        setProfiles(profilesData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const filteredProfiles = profiles.filter(profile =>
    profile.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleOpenModal = (profile) => {
    const formattedKid = {
      name: profile.name,
      age: profile.age,
      gender: profile.gender,
      voiceFile: profile.voiceFile,
      parent: profile.parent || {},
      lastActivity: profile.lastActivity ? new Date(profile.lastActivity).toLocaleString() : 'N/A',
      lastLesson: profile.lastLevel || 'N/A',
      progress: `${profile.progress || 0}%`,
      voiceUploader: profile.voiceUploader,
      score: profile.score
    };
    
    setSelectedKid(formattedKid);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedKid(null);
  };

  const handleVoiceUpload = (profile) => {
    navigate('/admin/voice-list/upload', {
      state: {
        name: profile.name,
        age: profile.age,
        gender: profile.gender,
        voiceUploader: profile.voiceUploader,
        voiceUploaderRole: profile.voiceUploaderRole,
        voiceUploadedAt: profile.voiceUploadedAt ? new Date(profile.voiceUploadedAt).toLocaleDateString() : 'N/A',
        id: profile.id
      }
    });
  };

  if (loading) return <div className="text-center p-4">Loading...</div>;
  if (error) return <div className="text-center text-red-500 p-4">{error}</div>;

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };
  
  // Add sorting logic before the table rendering
  const sortedData = [...filteredProfiles].sort((a, b) => {
    if (!sortField) return 0;
  
    let aValue, bValue;
  
    switch (sortField) {
      case 'name':
      case 'gender':
        aValue = a[sortField]?.toLowerCase() || '';
        bValue = b[sortField]?.toLowerCase() || '';
        break;
      case 'age':
        aValue = Number(a[sortField]) || 0;
        bValue = Number(b[sortField]) || 0;
        break;
      case 'parent':
        aValue = a.parent?.name?.toLowerCase() || '';
        bValue = b.parent?.name?.toLowerCase() || '';
        break;
      case 'uploadedBy':
        aValue = a.voiceUploader?.toLowerCase() || '';
        bValue = b.voiceUploader?.toLowerCase() || '';
        break;
      case 'uploadDate':
        aValue = new Date(a.lastActivity || 0).getTime();
        bValue = new Date(b.lastActivity || 0).getTime();
        break;
      default:
        return 0;
    }
  
    if (sortDirection === 'asc') {
      return aValue > bValue ? 1 : -1;
    } else {
      return aValue < bValue ? 1 : -1;
    }
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredProfiles.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(Math.min(Math.max(1, pageNumber), totalPages));
  };
  
  const handleLimitChange = (event) => {
    const newLimit = parseInt(event.target.value);
    setItemsPerPage(newLimit);
    setCurrentPage(1);
  };

  return (
    <>
      {/* Stats Box */}
      <div className="mx-5 mb-6">
        <div className="bg-white rounded-2xl shadow-md p-4">
          <h3 className="text-lg font-semibold text-gray-700 mb-2">Kids Profile</h3>
          <div className="text-gray-600">
            <p className="mb-2 flex justify-between">
              <span>Total:</span>
              <span className="text-right mr-2">{stats.kidsProfile.Total}</span>
            </p>
            <p className="mb-2 flex justify-between">
              <span>Boys:</span>
              <span className="text-right mr-2">{stats.kidsProfile.Boy}</span>
            </p>
            <p className="mb-2 flex justify-between">
              <span>Girls:</span>
              <span className="text-right mr-2">{stats.kidsProfile.Girl}</span>
            </p>
          </div>
        </div>
      </div>

      {/* Voice Table */}
      <div className="bg-white rounded-2xl shadow-md p-6 mx-5">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-gray-700">Voice Management</h2>
          <div className="flex items-center space-x-2">
            <input 
              type="text" 
              placeholder="Search..." 
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="border rounded px-2 py-1"
            />
          </div>
        </div>

        <div className="overflow-x-auto">
        <table className="min-w-full table-auto">
        <thead>
          <tr className="bg-blue-100 text-blue-600">
            <th className="px-4 py-2 text-left">#</th>
            <th 
              className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
              onClick={() => handleSort('name')}
            >
              Name {sortField === 'name' && (sortDirection === 'asc' ? '↑' : '↓')}
            </th>
            <th 
              className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
              onClick={() => handleSort('gender')}
            >
              Gender {sortField === 'gender' && (sortDirection === 'asc' ? '↑' : '↓')}
            </th>
            <th 
              className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
              onClick={() => handleSort('age')}
            >
              Age {sortField === 'age' && (sortDirection === 'asc' ? '↑' : '↓')}
            </th>
            <th 
              className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
              onClick={() => handleSort('parent')}
            >
              Parent {sortField === 'parent' && (sortDirection === 'asc' ? '↑' : '↓')}
            </th>
            <th className="px-4 py-2 text-left">Voice Status</th>
            <th className="px-4 py-2 text-left">Voice File</th>
            <th 
              className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
              onClick={() => handleSort('uploadedBy')}
            >
              Uploaded By {sortField === 'uploadedBy' && (sortDirection === 'asc' ? '↑' : '↓')}
            </th>
            <th 
              className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
              onClick={() => handleSort('uploadDate')}
            >
              Upload Date {sortField === 'uploadDate' && (sortDirection === 'asc' ? '↑' : '↓')}
            </th>
            <th className="px-4 py-2 text-center">Action</th>
          </tr>
        </thead>
  <tbody className="text-gray-600">
    {currentItems.map((profile, index) => (
      <tr key={profile.id} className="border-b">
        <td className="px-4 py-2">{index + 1}</td>
        <td className="px-4 py-2">{profile.name}</td>
        <td className="px-4 py-2">{profile.gender}</td>
        <td className="px-4 py-2">{profile.age}</td>
        <td className="px-4 py-2">{profile.parent?.name || 'N/A'}</td>
        <td className="px-4 py-2">
          <span className={`px-2 py-1 rounded-full text-xs ${
            profile.voiceFile ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
          }`}>
            {profile.voiceFile ? 'Uploaded' : 'Not Uploaded'}
          </span>
        </td>
        <td className="px-4 py-2">
          {profile.voiceFile ? (
            <a 
              href={profile.voiceFile}
              className="text-blue-500 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              View File
            </a>
          ) : 'No file'}
        </td>
        <td className="px-4 py-2">{profile.voiceUploader || 'N/A'}</td>
        <td className="px-4 py-2">
          {profile.lastActivity ? new Date(profile.lastActivity).toLocaleDateString() : 'N/A'}
        </td>
        <td className="px-4 py-2">
          <div className="flex justify-center space-x-2">
          <Eye 
                size={16} 
                className="text-blue-500 cursor-pointer" 
                onClick={() => handleOpenModal(profile)}
            />
            <Edit size={16} className="text-green-500 cursor-pointer" />
            <CloudUpload 
                size={16} 
                className="text-blue-300 cursor-pointer" 
                onClick={() => handleVoiceUpload(profile)}
            />
          </div>
        </td>
      </tr>
    ))}
  </tbody>
</table>
    <div className="flex justify-between items-center mt-4">
      <div className="flex items-center space-x-2">
        <span>Show:</span>
        <select 
          className="border rounded px-2 py-1"
          value={itemsPerPage}
          onChange={handleLimitChange}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={filteredProfiles.length}>All</option>
        </select>
        <span>entries</span>
      </div>

      <div className="flex items-center space-x-2">
        <button 
          className="border rounded px-3 py-1 hover:bg-gray-100"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &lt;
        </button>

        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index + 1}
            className={`px-3 py-1 rounded ${
              currentPage === index + 1 
                ? 'bg-blue-500 text-white' 
                : 'border hover:bg-gray-100'
            }`}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </button>
        ))}

        <button 
          className="border rounded px-3 py-1 hover:bg-gray-100"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          &gt;
        </button>
      </div>

      <div className="text-gray-600">
        Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, filteredProfiles.length)} of {filteredProfiles.length} entries
      </div>
    </div>
        </div>
        {isModalOpen && (
        <KidsProfileModal 
          kid={selectedKid} 
          onClose={handleCloseModal}
        />
      )}
      </div>
    </>
  );
};

export default VoiceTable;
import React from "react";
import NewKidsProfile from "./KidsProfile";
import StatsBoxes from "./StateBoxes";
import DashboardBoxes from "./DashboardBoxes";

const Dashboard = () => {
  return (
    <div>
      {/* Header */}
      <div className="flex justify-between items-center mb-6 ml-5">
        <h1 className="text-2xl font-bold">Dashboard</h1>
      </div>

      {/* Dashboard Stats */}
      <StatsBoxes />

      {/* Dashboard Boxes */}
      <DashboardBoxes />

      {/* New Kids Profile Table */}
      <NewKidsProfile />
    </div>
  );
};

export default Dashboard;

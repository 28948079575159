import React from 'react';

const ParentAccountModal = ({ parent, onClose }) => {
  if (!parent) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold text-gray-800">Parent Account Details</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          
          <div className="flex flex-col items-center mb-6">
            <img src="/api/placeholder/100/100" alt={parent.name} className="w-24 h-24 rounded-full mb-2" />
          </div>
          
          <div className="grid grid-cols-2 gap-4 mb-6">
            <div className="text-left">
              <p className="text-sm text-gray-600 my-3">Name</p>
              <p className="text-sm text-gray-600 my-3">Gender</p>
              <p className="text-sm text-gray-600 my-3">Email</p>
              <p className="text-sm text-gray-600 my-3">Mobile</p>
              <p className="text-sm text-gray-600 my-3">Status</p>
              <p className="text-sm text-gray-600 my-3">Creation Date</p>
              <p className="text-sm text-gray-600 my-3">Last Login</p>
            </div>
            <div className="text-right">
                <p className="text-sm text-gray-800 my-3">{parent.name}</p>
                <p className="text-sm text-gray-800 my-3">Woman</p>
                <p className="text-sm text-gray-800 my-3">{parent.email}</p>
                <p className="text-sm text-gray-800 my-3">{parent.mobileNumber}</p>
                <p className="text-sm text-gray-800 my-3">{parent.status}</p>
                <p className="text-sm text-gray-800 my-3">{parent.creationDate}</p>
                <p className="text-sm text-gray-800 my-3">{parent.lastLogin}</p>
            </div>
          </div>
          
          {/* <div className="mb-6 border border-gray-200 rounded-lg p-4">
            <h4 className="text-lg font-semibold mb-2">Device</h4>
            <div className="overflow-x-auto">
              <table className="min-w-full table-auto">
                <thead>
                  <tr className="bg-blue-100 text-blue-600">
                    <th className="px-4 py-2 text-left">#</th>
                    <th className="px-4 py-2 text-left">Device ID</th>
                    <th className="px-4 py-2 text-left">Model</th>
                    <th className="px-4 py-2 text-left">OS</th>
                    <th className="px-4 py-2 text-left">App Version</th>
                    <th className="px-4 py-2 text-left">Location Data</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600">
                  <tr className="border-b">
                    <td className="px-4 py-2">1</td>
                    <td className="px-4 py-2">{parent.deviceDetails.deviceId}</td>
                    <td className="px-4 py-2">{parent.deviceDetails.model}</td>
                    <td className="px-4 py-2">{parent.deviceDetails.os}</td>
                    <td className="px-4 py-2">{parent.deviceDetails.appVersion}</td>
                    <td className="px-4 py-2">{parent.deviceDetails.location}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> */}
          
          <div className="border border-gray-200 rounded-lg p-4">
  <h4 className="text-lg font-semibold mb-2">Child Profiles</h4>
  <div className="overflow-x-auto">
    <table className="min-w-full table-auto">
      <thead>
        <tr className="bg-blue-100 text-blue-600">
          <th className="px-4 py-2 text-left">#</th>
          <th className="px-4 py-2 text-left">Kids Name</th>
          <th className="px-4 py-2 text-left">Last Activity</th>
          <th className="px-4 py-2 text-left">Lesson</th>
          <th className="px-4 py-2 text-left">Progress</th>
        </tr>
      </thead>
      <tbody className="text-gray-600">
        {parent.childrenDetails.kidsName !== 'No children' ? (
          parent.childrenDetails.kidsName.split(', ').map((name, index) => (
            <tr key={index} className="border-b">
              <td className="px-4 py-2">{index + 1}</td>
              <td className="px-4 py-2">{name}</td>
              <td className="px-4 py-2">
                {parent.childrenDetails.lastActivity.split(', ')[index] || 'N/A'}
              </td>
              <td className="px-4 py-2">
                {parent.childrenDetails.lesson.split(', ')[index] || 'N/A'}
              </td>
              <td className="px-4 py-2">
                {parent.childrenDetails.progress.split(', ')[index] || '0%'}
              </td>
            </tr>
          ))
        ) : (
          <tr className="border-b">
            <td colSpan="5" className="px-4 py-2 text-center">No children found</td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
</div>
        </div>
      </div>
    </div>
  );
};

export default ParentAccountModal;
import React, { useState } from 'react';

const ProfileUserModal = ({ user, onClose }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/gif")) {
      setSelectedFile(file);
      setError(null);
    } else {
      setError("Please select a valid image file (JPEG, PNG, or GIF)");
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setError("Please select a file first");
      return;
    }

    setUploading(true);
    const formData = new FormData();
    formData.append('profilePicture', selectedFile);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/profile`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error('Upload failed');
      }

      // Force reload of parent component
      window.location.reload();
      onClose();
    } catch (err) {
      setError('Failed to upload image');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold text-gray-800">Update Profile Picture</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          
          <div className="flex flex-col items-center mb-6">
            <img 
              src={selectedFile ? URL.createObjectURL(selectedFile) : (user?.profilePicture || "/placeholder.png")} 
              alt="Profile Preview" 
              className="w-32 h-32 rounded-full object-cover mb-4"
            />
          </div>
        
          <div className="border border-gray-200 rounded-lg p-4">
            <h4 className="text-lg font-semibold mb-2">Upload New Picture</h4>
            <div className="flex flex-col gap-4">
              <input 
                type="file" 
                onChange={handleFileSelect}
                accept="image/jpeg,image/png,image/gif"
                className="text-sm text-gray-600"
              />
              {error && <p className="text-red-500 text-sm">{error}</p>}
              <button
                onClick={handleUpload}
                disabled={!selectedFile || uploading}
                className={`bg-blue-500 text-white px-4 py-2 rounded-lg ${
                  (!selectedFile || uploading) ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'
                }`}
              >
                {uploading ? 'Uploading...' : 'Upload'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileUserModal;
import React from 'react';
import { useDropzone } from 'react-dropzone';

const ImageDropzone = ({ fieldName, label, onDrop, file }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => onDrop(acceptedFiles, fieldName),
    accept: 'image/*'
  });

  return (
    <div className="flex flex-col">
      <label className="text-gray-700">{label}</label>
      <div
        {...getRootProps()}
        className={`border rounded px-2 py-4 text-center cursor-pointer ${
          isDragActive ? 'bg-blue-100' : 'bg-white'
        }`}
      >
        <input {...getInputProps()} />
        {file ? (
          <p>{file.name}</p>
        ) : (
          <p>Drag & drop an image here, or click to select one</p>
        )}
      </div>
    </div>
  );
};

export default ImageDropzone;
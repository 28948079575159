import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Undo2 } from 'lucide-react';

const ParentAccountEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const parentData = location.state;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    name: parentData?.name || '',
    email: parentData?.email || '',
    phoneNo: parentData?.phoneNo || '',
    bookCode: parentData?.bookCode || '',
    type: parentData?.type || '',
    isActive: parentData?.isActive ?? true
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
    setError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
  
    // Clean form data before submission
    const cleanedFormData = {
      ...formData,
      phoneNo: formData.phoneNo === 'N/A' || formData.phoneNo === '' ? null : formData.phoneNo,
      email: formData.email === 'N/A' || formData.email === '' ? null : formData.email
    };

    if (cleanedFormData.phoneNo === null) {
      delete cleanedFormData.phoneNo;
    }

    if (cleanedFormData.email === null) {
      delete cleanedFormData.email;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/parent/${parentData.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(cleanedFormData)
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        throw new Error(data.message || 'Failed to update parent account');
      }
  
      navigate(-1);
    } catch (err) {
      setError(err.message);
      console.error('Error updating parent account:', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBack = () => navigate(-1);

  return (
    <>
      <div className="flex justify-between items-center mb-2 ml-4">
        <h1 className="text-2xl font-bold">Edit Parent Account</h1>
        <button 
          className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 mr-5"
          onClick={handleBack}
        >
          <Undo2 size={20} />
        </button>
      </div>
      <p className="text-gray-500 text-sm ml-4">
        Parent Management <span className="text-gray-400">&gt; Edit Parent Account</span>
      </p>
      <div className="bg-white rounded-2xl shadow-md p-6 m-5">
        {error && (
          <div className="mb-4 p-3 bg-red-100 text-red-600 rounded-lg">
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label className="text-gray-700">Name</label>
              <input 
                type="text" 
                name="name" 
                value={formData.name} 
                onChange={handleChange} 
                placeholder="Enter Parent's Name"
                className="border rounded px-2 py-1"
                required
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700">Email</label>
              <input 
                type="text"
                name="email" 
                value={formData.email === null ? '' : formData.email} 
                onChange={handleChange} 
                placeholder="Enter Email"
                className="border rounded px-2 py-1"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700">Phone Number</label>
              <input 
                type="tel" 
                name="phoneNo" 
                value={formData.phoneNo === null ? '' : formData.phoneNo} 
                onChange={handleChange} 
                placeholder="Enter Phone Number"
                className="border rounded px-2 py-1"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700">Book Code</label>
              <input 
                type="text" 
                name="bookCode" 
                value={formData.bookCode} 
                onChange={handleChange} 
                placeholder="Enter Book Code"
                className="border rounded px-2 py-1"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700">Account Type</label>
              <select 
                name="type" 
                value={formData.type} 
                onChange={handleChange}
                className="border rounded px-2 py-1"
                required
              >
                <option value="">Select Type</option>
                <option value="Premium">Premium</option>
                <option value="Registered">Registered</option>
              </select>
            </div>
            <div className="flex items-center space-x-2">
              <input 
                type="checkbox" 
                name="isActive" 
                checked={formData.isActive} 
                onChange={handleChange}
                className="h-4 w-4"
              />
              <label className="text-gray-700">Active Account</label>
            </div>
          </div>
          <div className="flex justify-end">
            <button 
              type="submit" 
              className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 disabled:bg-blue-300"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ParentAccountEdit;

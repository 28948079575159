import React, { useState } from 'react';
import PropTypes from 'prop-types';

const FeedbackModal = ({ feedback, onClose, onStatusUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const updateFeedbackStatus = async (status) => {
    try {
      setIsLoading(true);
      setError(null);
      setSuccessMessage(null);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/edit-feedback/${feedback.feedbackId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ status })
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to update feedback status');
      }

      setSuccessMessage('Status updated successfully');
      // Only call onStatusUpdate if it exists
      if (typeof onStatusUpdate === 'function') {
        onStatusUpdate();
      }
      setTimeout(() => {
        onClose(); // Close modal after 1 second
      }, 1000);

    } catch (err) {
      console.error('Error updating feedback status:', err);
      setError(err.message || 'Failed to update status');
    } finally {
      setIsLoading(false);
    }
  };

  if (!feedback) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center p-4 z-[9999]">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-2xl text-center font-semibold text-gray-800 w-full">Feedback</h3>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          
          <div className="grid grid-cols-2 gap-4 mb-6">
            <div className="text-left">
              <p className="text-sm text-gray-600 my-3">Parents Name</p>
              <p className="text-sm text-gray-600 my-3">Mobile Number</p>
              <p className="text-sm text-gray-600 my-3">Parents Account</p>
              <p className="text-sm text-gray-600 my-3">Date</p>
            </div>
            <div className="text-right">
              <p className="text-sm text-gray-800 my-3">{feedback.parentName}</p>
              <p className="text-sm text-gray-800 my-3">{feedback.mobileNumber}</p>
              <p className="text-sm text-gray-800 my-3">{feedback.email}</p>
              <p className="text-sm text-gray-800 my-3">{feedback.date}</p>
            </div> 
          </div>
          <h4 className="text-lg font-semibold mb-2">Message</h4>
          <div className="border border-gray-200 rounded-lg p-4 bg-gray-100 mb-6">
            <p className="text-sm text-gray-800">{feedback.message}</p>
          </div>
          {error && (
            <div className="text-red-500 text-sm mb-4 text-center">
              {error}
            </div>
          )}

          {/* Action Buttons */}
          <div className="flex justify-end space-x-4">
            <button 
              onClick={() => updateFeedbackStatus('Checked')}
              disabled={isLoading}
              className={`px-4 py-2 border-2 border-blue-500 text-blue-500 rounded-lg transition-colors
                ${isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-50'}`}
            >
              {isLoading ? 'Updating...' : 'Checked'}
            </button>
            <button 
              onClick={() => updateFeedbackStatus('Issue')}
              disabled={isLoading}
              className={`px-4 py-2 border-2 border-red-500 bg-red-500 text-white rounded-lg transition-colors
                ${isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-red-600'}`}
            >
              {isLoading ? 'Updating...' : 'Issue'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackModal;
// AdminUserModal.js
import React from 'react';
import { X } from 'lucide-react';

const AdminUserModal = ({ user, onClose }) => {
  if (!user) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-xl w-1/2 max-h-[80vh] overflow-y-auto">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-xl font-semibold">Admin User Details</h2>
          <button onClick={onClose}>
            <X size={24} className="text-gray-500 hover:text-gray-700" />
          </button>
        </div>
        
        <div className="p-6">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-gray-600 font-semibold">Name</p>
              <p>{user.name}</p>
            </div>
            <div>
              <p className="text-gray-600 font-semibold">Email</p>
              <p>{user.email}</p>
            </div>
            <div>
              <p className="text-gray-600 font-semibold">Phone Number</p>
              <p>{user.phoneNumber || 'N/A'}</p>
            </div>
            <div>
              <p className="text-gray-600 font-semibold">Role</p>
              <p>{user.role}</p>
            </div>
            <div>
              <p className="text-gray-600 font-semibold">Designation</p>
              <p>{user.designation || 'N/A'}</p>
            </div>
            <div>
              <p className="text-gray-600 font-semibold">Created At</p>
              <p>{new Date(user.createdAt).toLocaleString()}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminUserModal;
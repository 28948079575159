// NotificationPanel.js
import React, { useState } from "react";
import { Megaphone, Clock } from "lucide-react";

const NotificationPanel = ({ notifications, loading, onNotificationClick }) => {
  const [showAll, setShowAll] = useState(false);

  const displayedNotifications = showAll 
    ? notifications.slice(0, 6) 
    : notifications.slice(0, 3);

  const formatTimeAgo = (dateString) => {
    const now = new Date();
    const date = new Date(dateString);
    const seconds = Math.floor((now - date) / 1000);

    if (seconds < 60) return `${seconds} seconds ago`;
    if (seconds < 3600) return `${Math.floor(seconds / 60)} minutes ago`;
    if (seconds < 86400) return `${Math.floor(seconds / 3600)} hours ago`;
    return `${Math.floor(seconds / 86400)} days ago`;
  };

  if (loading) {
    return (
      <div className="bg-white shadow-lg rounded-xl w-80 p-4">
        <p className="text-center">Loading notifications...</p>
      </div>
    );
  }

  return (
    <div className="bg-white shadow-lg rounded-xl w-80 p-4 text-gray-700">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">Feedback Notifications</h2>
        <button 
          onClick={() => setShowAll(!showAll)}
          className="text-sm text-blue-500 hover:underline"
        >
          {showAll ? 'Show Less' : 'View All'}
        </button>
      </div>

      {notifications.length === 0 ? (
        <p className="text-center text-gray-500">No new notifications</p>
      ) : (
        <ul>
          {displayedNotifications.map((notification) => (
            <li
              key={notification.id}
              onClick={() => onNotificationClick(notification.id)}
              className={`flex items-start py-3 border-b last:border-none cursor-pointer hover:bg-gray-50`}
            >
              <div className="flex-shrink-0">
                <Megaphone className={`w-6 h-6 ${notification.checked ? 'text-gray-400' : 'text-blue-500'}`} />
              </div>
              <div className="ml-3 flex-1">
                <h3 className={`font-medium text-sm ${!notification.checked ? 'text-blue-500' : ''}`}>
                  {notification.title}
                </h3>
                <p className="text-xs text-gray-500">{notification.content}</p>
                <p className="text-xs text-gray-400 mt-1 flex items-center">
                  <Clock className="w-4 h-4 mr-1" />
                  {formatTimeAgo(notification.time)}
                </p>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default NotificationPanel;
// DropdownPanel.js
import React from 'react';
import { User, LogOut } from 'lucide-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import logout from '../Logout';

const DropdownPanel = ({ onClose = () => {} }) => {
  const handleLogout = () => {
    logout();
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <div className="origin-top-right absolute right-0 mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
      <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
        <Link 
          to="user-profile" 
          className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:bg-gray-200 active:bg-gray-200"
          role="menuitem"
          onClick={() => typeof onClose === 'function' && onClose()}
        >
          <User className="mr-2 w-4 h-4" />
          Profile
        </Link>
        
        <hr className="border-t border-gray-200 my-1" />
        <button 
          onClick={handleLogout}
          className="w-full block px-4 py-2 text-sm text-red-700 hover:bg-gray-100 flex items-center" 
          role="menuitem"
        >
          <LogOut className="mr-2 w-4 h-4" />
          Logout
        </button>
      </div>
    </div>
  );
};

DropdownPanel.propTypes = {
  onClose: PropTypes.func
};

export default DropdownPanel;
import React, { useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CloudUpload, X } from 'lucide-react';
import { useDropzone } from 'react-dropzone';

const VoiceUpload = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const kid = location.state;
  
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file && file.type === 'audio/mpeg') {
      setSelectedFile(file);
      setError(null);
    } else {
      setError('Please upload an MP3 file');
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'audio/mpeg': ['.mp3']
    },
    multiple: false
  });

  const handleUpload = async () => {
    if (!selectedFile) return;

    setUploading(true);
    const formData = new FormData();
    formData.append('voiceFile', selectedFile);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/voice-upload/${kid.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: formData,
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          setUploadProgress(Math.round(progress));
        }
      });

      if (!response.ok) throw new Error('Upload failed');
      
      navigate(-1);
    } catch (err) {
      setError(err.message);
      setUploading(false);
    }
  };

  return (
<div className="mx-5 space-y-6">
  {/* Top Box */}
  <div className="bg-white rounded-lg shadow-xl p-6 w-full">
    <h2 className="text-xl font-semibold text-gray-800 mb-6">Voice Upload</h2>

    <div className="grid grid-cols-2 gap-x-8 gap-y-4 mb-6">
      {/* Left Column */}
      <div>
        <div className="space-y-4">
          <div>
            <p className="text-sm text-gray-600">Kids Name</p>
            <p className="text-sm font-medium text-gray-800">{kid?.name || 'N/A'}</p>
          </div>
          <div>
            <p className="text-sm text-gray-600">Gender</p>
            <p className="text-sm font-medium text-gray-800">{kid?.gender || 'N/A'}</p>
          </div>
          <div>
            <p className="text-sm text-gray-600">Age</p>
            <p className="text-sm font-medium text-gray-800">{kid?.age || 'N/A'}</p>
          </div>
        </div>
      </div>

      {/* Right Column */}
      <div>
        <div className="space-y-4">
        <div>
        <p className="text-sm text-gray-600">Upload By</p>
        <p className="text-sm font-medium text-gray-800">{kid?.voiceUploader || 'N/A'}</p>
      </div>
      {/* <div>
        <p className="text-sm text-gray-600">Roles</p>
        <p className="text-sm font-medium text-gray-800">{kid?.voiceUploaderRole || 'N/A'}</p>
      </div>
      <div>
        <p className="text-sm text-gray-600">Date</p>
        <p className="text-sm font-medium text-gray-800">
          {kid?.voiceUploadedAt ? new Date(kid.voiceUploadedAt).toLocaleDateString() : 'N/A'}
        </p>
      </div> */}
        </div>
      </div>
    </div>
  </div>

  {/* Upload Box */}
  <div className="bg-white rounded-lg shadow-xl p-6 w-1/2 mx-auto">
    <h2 className="text-xl font-semibold text-gray-800 mb-4">File Upload</h2>
    <hr className="border-gray-200 mb-6" />
    
    {/* Upload Area */}
    <div
      {...getRootProps()}
      className={`border-2 border-dashed rounded-lg p-8 mb-4 transition-colors
        ${isDragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'}
        ${error ? 'border-red-500' : ''}
      `}
    >
      <input {...getInputProps()} />
      {!selectedFile ? (
        <div className="flex flex-col items-center justify-center text-center">
          <CloudUpload size={40} className={`mb-4 ${isDragActive ? 'text-blue-500' : 'text-gray-400'}`} />
          <p className="text-gray-600 mb-2">
            {isDragActive ? 'Drop the file here' : 'Click or Drag file to this area to upload'}
          </p>
          <p className="text-sm text-gray-400">Formats accepted are .mp3</p>
        </div>
      ) : (
        <div className="flex flex-col items-center">
          <div className="flex items-center justify-between w-full mb-4">
            <span className="text-sm text-gray-600">{selectedFile.name}</span>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setSelectedFile(null);
                setError(null);
              }}
              className="text-gray-500 hover:text-red-500"
            >
              <X size={20} />
            </button>
          </div>
          {uploading && (
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div 
                className="bg-blue-500 h-2.5 rounded-full transition-all duration-300"
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
          )}
        </div>
      )}
    </div>

    {error && (
      <p className="text-red-500 text-sm mb-4">{error}</p>
    )}

    <hr className="border-gray-200 mb-6" />
    
    {/* Action Buttons */}
    <div className="flex justify-end space-x-4">
      <button 
        onClick={() => navigate(-1)}
        className="px-4 py-2 text-gray-600 border border-gray-300 rounded-lg hover:bg-gray-50"
        disabled={uploading}
      >
        Cancel
      </button>
      <button 
        onClick={handleUpload}
        disabled={!selectedFile || uploading}
        className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:bg-blue-300"
      >
        {uploading ? `Uploading ${uploadProgress}%` : 'Continue'}
      </button>
    </div>
  </div>
</div>

  );
};

export default VoiceUpload;
import React, { useState, useEffect } from 'react';
import { PencilLine, Upload, Trash2 } from 'lucide-react';
import { Link } from 'react-router-dom';
import logo from './../../images/alternative_admin_image.png';
import ProfileUserModal from './ProfileUserModal';

const ProfileUser = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [adminData, setAdminData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    const fetchAdminProfile = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/profile`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          },
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        if (data.success && data.data) {
          setAdminData(data.data);
          setError(null);
        } else {
          throw new Error('Invalid data format received');
        }
      } catch (error) {
        console.error('Error fetching admin profile:', error);
        setError('Failed to load profile data');
      } finally {
        setLoading(false);
      }
    };
  
    fetchAdminProfile();
  }, []);

  const handleDeletePicture = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/profile-picture`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) throw new Error('Failed to delete profile picture');

      // Update local state
      setAdminData(prev => ({
        ...prev,
        profilePicture: null
      }));
      
      setShowDeleteConfirm(false);
    } catch (error) {
      console.error('Error deleting profile picture:', error);
      setError('Failed to delete profile picture');
    }
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  if (loading) return <div>Loading...</div>;

  return (
    <div className="flex flex-col gap-6 mx-5">
      {/* Top Row */}
      <div className="flex justify-between items-center bg-white rounded-2xl shadow-md p-4">
    <div className="flex items-center m-2">
      <img 
        src={adminData?.profilePicture || logo} 
        alt="Profile" 
        className="w-24 h-24 rounded-full object-cover" 
      />
    </div>
    <div className="flex items-center gap-4">
      <button 
        onClick={() => setShowDeleteConfirm(true)} 
        className="bg-white text-red-500 px-4 py-2 rounded-lg flex items-center gap-2 border border-gray-300 shadow-sm hover:bg-red-100 disabled:opacity-50 disabled:cursor-not-allowed"
        disabled={!adminData?.profilePicture}
      >
        <Trash2 className="w-4 h-4" />
      </button>
      <button 
        onClick={handleOpenModal}
        className="bg-white text-blue-500 px-4 py-2 rounded-lg flex items-center gap-2 border border-gray-300 shadow-sm hover:bg-blue-100"
      >
        <Upload className="w-4 h-4" />
        <span>Upload</span>
      </button>
    </div>
  </div>

      {/* Bottom Row */}
      <div className="bg-white rounded-2xl shadow-md p-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-3xl font-semibold text-blue-500">Personal Information</h3>
          <Link to="user-profile-edit" className="bg-white text-black px-4 py-2 rounded-lg flex items-center gap-2 border border-gray-300 shadow-sm">
            <PencilLine className="w-4 h-4" />
            Edit
          </Link>
        </div>
        <div className="inline mb-4 bg-gray-200 h-1"></div> {/* Inline added here with gray background */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-500">Name</label>
            <p className="text-gray-600 text-lg">{adminData?.name}</p>
          </div>
          <div>
            <label className="block text-gray-500">User Role</label>
            <p className="text-gray-600 text-lg">{adminData?.role}</p>
          </div>
          <div>
            <label className="block text-gray-500">Email</label>
            <p className="text-gray-600 text-lg">{adminData?.email}</p>
          </div>
          <div>
            <label className="block text-gray-500">Phone</label>
            <p className="text-gray-600 text-lg">{adminData?.phoneNumber}</p>
          </div>
        </div>
      </div>

      {/* Password Change Button */}
      <div className="flex justify-start">
        <Link to="user-password-change" className="bg-white text-black px-4 py-2 rounded-lg flex items-center gap-2 border border-blue-400 shadow-sm">
          <PencilLine className="w-4 h-4" />
          Password Change
        </Link>
      </div>

      {/* Modal */}
      {isModalOpen && <ProfileUserModal user={adminData} onClose={handleCloseModal} />}
      {showDeleteConfirm && (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center p-4">
      <div className="bg-white rounded-lg p-6 max-w-sm">
        <h3 className="text-lg font-semibold mb-4">Delete Profile Picture?</h3>
        <p className="text-gray-600 mb-6">Are you sure you want to remove your profile picture?</p>
        <div className="flex justify-end gap-4">
          <button
            onClick={() => setShowDeleteConfirm(false)}
            className="px-4 py-2 text-gray-600 hover:text-gray-800"
          >
            Cancel
          </button>
          <button
            onClick={handleDeletePicture}
            className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  )}
    </div>
  );
};

export default ProfileUser;
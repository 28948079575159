import React, { useState, useEffect } from 'react';
import { Eye, Printer, Trash2 } from 'lucide-react';
import { jsPDF } from 'jspdf';
import QRCode from 'qrcode';
import logo from '../../images/company_logo.png';

const QRPrint = () => {
  const [activeTab, setActiveTab] = useState('Unpainted');
  const [searchQuery, setSearchQuery] = useState('');
  const [qrCodes, setQrCodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [unpaintedSelected, setUnpaintedSelected] = useState(false);

  const [newCount, setNewCount] = useState(0);
  const [unpaintedCount, setUnpaintedCount] = useState(0);

  const [customRangeSelected, setCustomRangeSelected] = useState(false);
  const [rangeStart, setRangeStart] = useState(0);
  const [rangeEnd, setRangeEnd] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [qrToDelete, setQrToDelete] = useState(null);

  const [showStatusModal, setShowStatusModal] = useState(false);
  const [pendingStatus, setPendingStatus] = useState(null);

  // Fetch QR codes
  useEffect(() => {
    console.log('QRPrint component mounted');
    fetchQRCodes();
  }, []); // Empty dependency array means it runs once on mount

  const fetchQRCodes = async () => {
    setLoading(true); // Start loading
    try {
      console.log('Fetching QR codes...');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/view-all-qr`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch QR codes');
      }

      const data = await response.json();
      console.log('Fetched data:', data);
      setQrCodes(data);
      setError(null);
    } catch (err) {
      setError('Failed to load QR codes');
      console.error('Error fetching QR codes:', err);
    } finally {
      setLoading(false);
    }
  };

  const generateQRWithLogo = async (code, size) => {
    // Create QR code with space for logo
    const qrDataUrl = await QRCode.toDataURL(code, {
      width: size,
      height: size,
      margin: 1,
      errorCorrectionLevel: 'H', // High error correction for logo space
      color: {
        dark: '#000000',
        light: '#ffffff',
      },
      maskPattern: 0,
      version: 3,  // Stable version for consistent layout
    });
  
    // Create canvas to combine QR and logo
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const qrImage = new Image();
    const logoImage = new Image();
  
    return new Promise((resolve) => {
      qrImage.onload = () => {
        canvas.width = size;
        canvas.height = size;
        ctx.drawImage(qrImage, 0, 0, size, size);
  
        logoImage.onload = () => {
          // Calculate logo size and position (20% of QR code size)
          const logoSize = size * 0.2;
          const logoX = (size - logoSize) / 2;
          const logoY = (size - logoSize) / 2;
  
          // Create white background for logo
          ctx.fillStyle = '#FFFFFF';
          ctx.fillRect(logoX - 2, logoY - 2, logoSize + 4, logoSize + 4);
  
          // Draw logo
          ctx.drawImage(logoImage, logoX, logoY, logoSize, logoSize);
          resolve(canvas.toDataURL('image/png'));
        };
        logoImage.src = logo;  // Uses imported logo
      };
      qrImage.src = qrDataUrl;
    });
  };

  // Print QR code handler
  const handlePrint = async (code) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/update-qr-status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ 
          codes: code,
          status: 'Painted'
        })
      });
  
      if (!response.ok) throw new Error('Failed to update QR code status');
  
      // Update local state after successful print
      setQrCodes(prevCodes => 
        prevCodes.map(qr => 
          qr.code === code ? { ...qr, status: 'Painted' } : qr
        )
      );
    } catch (err) {
      setError(err.message);
    }
  };

  const handleStatusChange = async (newStatus) => {
    setLoading(true);
    try {
      // Get selected QR codes
      const selectedCodes = filteredData
        .filter(item => selectedItems.includes(item._id))
        .map(item => item.code);
  
      // Update status
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/update-qr-status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ 
          codes: selectedCodes,
          status: newStatus 
        })
      });
  
      if (!response.ok) throw new Error(`Failed to update status to ${newStatus}`);
  
      // Update local state
      setQrCodes(prevCodes => 
        prevCodes.map(qr => 
          selectedItems.includes(qr._id) ? { ...qr, status: newStatus } : qr
        )
      );
  
      setSelectedItems([]); // Clear selections
    } catch (err) {
      setError(`Failed to update status to ${newStatus}`);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Updated filter logic
  const filteredData = qrCodes.filter((item) => {
    const matchesSearchQuery = item.code.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.created_at.toLowerCase().includes(searchQuery.toLowerCase());

    if (!matchesSearchQuery) return false;
    return item.status === activeTab;
  });

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedItems(filteredData.map(item => item._id));
    } else {
      setSelectedItems([]);
    }
  };

  const handleSelectItem = (id) => {
    setSelectedItems(prev => {
      if (prev.includes(id)) {
        return prev.filter(item => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  const handleUnpaintedSelect = (e) => {
    const unpaintedItems = filteredData.filter(item => item.status === 'Unpainted');
    if (e.target.checked) {
      setSelectedItems(prev => [...new Set([...prev, ...unpaintedItems.map(item => item._id)])]);
      setUnpaintedSelected(true);
    } else {
      setSelectedItems(prev => prev.filter(id => 
        !unpaintedItems.find(item => item._id === id)
      ));
      setUnpaintedSelected(false);
    }
  };

  const handleNewCountChange = (e) => {
    const value = parseInt(e.target.value) || 0;
    const maxItems = filteredData.length;
    const validValue = Math.min(Math.max(0, value), maxItems);
    setNewCount(validValue);
    
    // Update selections based on new count
    const newSelection = filteredData
      .slice(0, validValue)
      .map(item => item._id);
    setSelectedItems(newSelection);
  };

  // Add handler for unpainted count change
  const handleUnpaintedCountChange = (e) => {
    const value = parseInt(e.target.value) || 0;
    const unpaintedItems = filteredData.filter(item => item.status === 'Unpainted');
    const maxUnpainted = unpaintedItems.length;
    const validValue = Math.min(Math.max(0, value), maxUnpainted);
    setUnpaintedCount(validValue);
    
    // Update selections for unpainted items
    const unpaintedSelection = unpaintedItems
      .slice(0, validValue)
      .map(item => item._id);
    setSelectedItems(prev => {
      const nonUnpaintedSelection = prev.filter(id => 
        !unpaintedItems.find(item => item._id === id)
      );
      return [...nonUnpaintedSelection, ...unpaintedSelection];
    });
  };

  const handleCustomRangeSelect = (e) => {
    if (e.target.checked) {
      const start = Math.max(1, rangeStart);
      const end = Math.min(filteredData.length, rangeEnd);
      const customSelection = filteredData
        .slice(start - 1, end)
        .map(item => item._id);
      setSelectedItems(customSelection);
      setCustomRangeSelected(true);
    } else {
      setSelectedItems([]);
      setCustomRangeSelected(false);
    }
  };
  
  // Add range change handlers
  const handleRangeStartChange = (e) => {
    const value = parseInt(e.target.value) || 0;
    const validValue = Math.max(1, Math.min(value, rangeEnd));
    setRangeStart(validValue);
    if (customRangeSelected) {
      const customSelection = filteredData
        .slice(validValue - 1, rangeEnd)
        .map(item => item._id);
      setSelectedItems(customSelection);
    }
  };
  
  const handleRangeEndChange = (e) => {
    const value = parseInt(e.target.value) || 0;
    const validValue = Math.min(Math.max(value, rangeStart), filteredData.length);
    setRangeEnd(validValue);
    if (customRangeSelected) {
      const customSelection = filteredData
        .slice(rangeStart - 1, validValue)
        .map(item => item._id);
      setSelectedItems(customSelection);
    }
  };

  const handlePaintMultiple = async () => {
    setLoading(true);
    try {
      // Get selected QR codes
      const selectedCodes = filteredData
        .filter(item => selectedItems.includes(item._id))
        .map(item => item.code);
  
      // Update status to Painted using updateQRStatus endpoint
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/update-qr-status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ 
          codes: selectedCodes,
          status: 'Painted'
        })
      });
  
      if (!response.ok) throw new Error('Failed to update QR codes status');
  
      // Update local state
      setQrCodes(prevCodes => 
        prevCodes.map(qr => 
          selectedItems.includes(qr._id) ? { ...qr, status: 'Painted' } : qr
        )
      );
  
      // Generate and download PDF
      await generateAndDownloadPDF(selectedCodes);
  
      setSelectedItems([]); // Clear selections
  
    } catch (err) {
      setError('Failed to paint QR codes');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  
  const generateAndDownloadPDF = async (codes) => {
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageWidth = 220;
    const pageHeight = 297;
    const qrSize = 40;
    const margin = 6;
    const qrPerRow = Math.floor((pageWidth - 2 * margin) / (qrSize + margin));
    const qrPerColumn = Math.floor((pageHeight - 2 * margin) / (qrSize + margin));
    const codesPerPage = qrPerRow * qrPerColumn;
  
    const promises = codes.map(code => generateQRWithLogo(code, qrSize * 3.78));
    const imgDataArray = await Promise.all(promises);
  
    imgDataArray.forEach((imgData, index) => {
      const x = 7 + margin + (index % qrPerRow) * (qrSize + margin);
      const y = 4 + margin + Math.floor((index % codesPerPage) / qrPerRow) * (qrSize + margin);
  
      pdf.addImage(imgData, 'PNG', x, y, qrSize, qrSize);
  
      if ((index + 1) % codesPerPage === 0 && index + 1 < codes.length) {
        pdf.addPage();
      }
    });
  
    const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
    pdf.save(`painted-qr-codes_${timestamp}.pdf`);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(Math.min(Math.max(1, pageNumber), totalPages));
  };
  
  const handleLimitChange = (event) => {
    const newLimit = parseInt(event.target.value);
    setItemsPerPage(newLimit);
    setCurrentPage(1); // Reset to first page when changing limit
  };

  const handleDeleteClick = (code) => {
    setQrToDelete(code);
    setShowDeleteModal(true);
  };
  
  const handleDeleteConfirm = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/delete-qr/${qrToDelete}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });
  
      if (!response.ok) throw new Error('Failed to delete QR code');
  
      // Update local state to remove the deleted item
      setQrCodes(prevCodes => prevCodes.filter(qr => qr.code !== qrToDelete));
      setShowDeleteModal(false);
      setQrToDelete(null);
  
    } catch (error) {
      console.error('Error deleting QR code:', error);
      setError('Failed to delete QR code');
    }
  };

  const handleStatusChangeClick = (newStatus) => {
    setPendingStatus(newStatus);
    setShowStatusModal(true);
  };
  
  const handleStatusChangeConfirm = async () => {
    setShowStatusModal(false);
    try {
      // Get selected QR codes before status change
      const selectedCodes = filteredData
        .filter(item => selectedItems.includes(item._id))
        .map(item => item.code);
  
      // Update status
      await handleStatusChange(pendingStatus);
  
      // If status was changed to 'Painted', generate and download PDF
      if (pendingStatus === 'Painted') {
        await generateAndDownloadPDF(selectedCodes);
      }
  
      setPendingStatus(null);
    } catch (err) {
      console.error('Error in status change and PDF generation:', err);
      setError('Failed to complete the operation');
    }
  };

  return (
    <>
      <div className="flex justify-between items-center mb-2 ml-4">
        <h1 className="text-2xl font-bold">Print QR</h1>
      </div>
      <p className="text-gray-500 text-sm ml-4">
        QR Management <span className="text-gray-400">&gt; Print QR</span>
      </p>
      <div className="bg-white rounded-2xl shadow-md p-6 m-5">
        {/* Updated Tabs */}
        <div className="flex justify-between items-center mb-4">
          <div className="flex space-x-4">
            <button
              className={`px-4 py-2 rounded ${activeTab === 'Unpainted' && (
                <button 
                  className="bg-orange-500 text-white py-2 px-4 rounded-lg hover:bg-orange-600 transition-colors"
                  onClick={handlePaintMultiple}
                  disabled={loading || selectedItems.length === 0}
                >
                  {loading ? 'Processing...' : 'Paint Now'}
                </button>
              )}`}
              onClick={() => setActiveTab('Unpainted')}
            >
              Unpainted
            </button>
            <button
              className={`px-4 py-2 rounded ${activeTab === 'Painted' ? 'text-blue-600 font-semibold' : 'text-gray-600'}`}
              onClick={() => setActiveTab('Painted')}
            >
              Painted
            </button>
            <button
              className={`px-4 py-2 rounded ${activeTab === 'Stickered' ? 'text-blue-600 font-semibold' : 'text-gray-600'}`}
              onClick={() => setActiveTab('Stickered')}
            >
              Stickered
            </button>
            <button
              className={`px-4 py-2 rounded ${activeTab === 'Expired' ? 'text-blue-600 font-semibold' : 'text-gray-600'}`}
              onClick={() => setActiveTab('Expired')}
            >
              Expired
            </button>
          </div>
          <div className="flex items-center space-x-2">
            <input 
              type="text" 
              placeholder="Search..." 
              value={searchQuery}
              onChange={handleSearchChange}
              className="border rounded px-2 py-1"
            />
            <button className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600">
              Filter
            </button>
          </div>
        </div>
<div className="flex justify-between items-start mb-4"> {/* Added container for both sections */}
  {/* Blue box with checkboxes */}
  <div className="flex items-center space-x-2 bg-blue-50  rounded-lg">
    {/* New section */}
    <div className="flex items-center space-x-2 border border-transparent p-2 rounded">
      <input
        type="checkbox"
        className="rounded border-gray-300"
        checked={selectedItems.length > 0}
        onChange={handleSelectAll}
      />
      <span className="text-gray-600">New</span>
      <input
        type="number"
        className="bg-gray-100 px-2 py-1 rounded text-gray-600 w-16"
        value={selectedItems.length}
        onChange={handleNewCountChange}
        min="0"
        max={filteredData.length}
      />
    </div>

    {/* Unpainted section */}
    <div className="flex items-center space-x-2 border border-transparent p-2 rounded">
      <input
        type="checkbox"
        className="rounded border-gray-300"
        checked={unpaintedSelected}
        onChange={handleUnpaintedSelect}
      />
      <span className="text-gray-600">Unpainted</span>
      <input
        type="number"
        className="bg-gray-100 px-2 py-1 rounded text-gray-600 w-16"
        value={filteredData.filter(item => 
          item.status === 'Unpainted' && 
          selectedItems.includes(item._id)
        ).length}
        onChange={handleUnpaintedCountChange}
        min="0"
        max={filteredData.filter(item => item.status === 'Unpainted').length}
      />
    </div>

    {/* Custom section */}
    <div className="flex items-center space-x-2 border border-transparent p-2 rounded">
      <input
        type="checkbox"
        className="rounded border-gray-300"
        checked={customRangeSelected}
        onChange={handleCustomRangeSelect}
      />
      <span className="text-gray-600">Custom</span>
      <input
        type="number"
        className="bg-gray-100 px-2 py-1 rounded text-gray-600 w-16"
        value={rangeStart}
        onChange={handleRangeStartChange}
        min="1"
        max={filteredData.length}
        placeholder="Start"
      />
      <span className="text-gray-600">to</span>
      <input
        type="number"
        className="bg-gray-100 px-2 py-1 rounded text-gray-600 w-16"
        value={rangeEnd}
        onChange={handleRangeEndChange}
        min={rangeStart}
        max={filteredData.length}
        placeholder="End"
      />
    </div>
  </div>

  {/* Action buttons moved outside and to the right */}
  <div className="flex items-center space-x-2 mt-1">
  {activeTab === 'Unpainted' && (
  <button 
    className="bg-orange-500 text-white py-2 px-4 rounded-lg hover:bg-orange-600 transition-colors"
    onClick={() => handleStatusChangeClick('Painted')}
    disabled={loading || selectedItems.length === 0}
  >
    {loading ? 'Processing...' : 'Paint Now'}
  </button>
)}

{activeTab === 'Painted' && (
  <button 
    className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors"
    onClick={() => handleStatusChangeClick('Stickered')}
    disabled={loading || selectedItems.length === 0}
  >
    {loading ? 'Processing...' : 'Stickered'}
  </button>
)}

{activeTab === 'Stickered' && (
  <button 
    className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 transition-colors"
    onClick={() => handleStatusChangeClick('Expired')}
    disabled={loading || selectedItems.length === 0}
  >
    {loading ? 'Processing...' : 'Expired'}
  </button>
)}
</div>
</div>
    
        
        
        

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto">
            {/* Table Head */}
            <thead>
              <tr className="bg-blue-100 text-blue-600">
                <th className="px-4 py-2 text-left">
                  <input
                    type="checkbox"
                    className="rounded border-gray-300"
                    checked={selectedItems.length === filteredData.length}
                    onChange={handleSelectAll}
                  />
                </th>
                <th className="px-4 py-2 text-left">#</th>
                <th className="px-4 py-2 text-left">QR Code</th>
                <th className="px-4 py-2 text-left">Date of Generate</th>
                <th className="px-4 py-2 text-left">Generated By</th>
                <th className="px-4 py-2 text-left">Status</th>
                <th className="px-4 py-2 text-left">Action</th>
              </tr>
            </thead>


            {/* Table Body */}
            <tbody className="text-gray-600">
  {currentItems.map((item, index) => (
    <tr key={item._id} className="border-b">
                    <td className="px-4 py-2">
                      <input
                        type="checkbox"
                        className="rounded border-gray-300"
                        checked={selectedItems.includes(item._id)}
                        onChange={() => handleSelectItem(item._id)}
                      />
                    </td>
                    <td className="px-4 py-2">{index + 1}</td>
                    <td className="px-4 py-2">{item.code}</td>
                    <td className="px-4 py-2">
                      {new Date(item.created_at).toLocaleDateString()}
                    </td>
                    <td className="px-4 py-2">
                      {item.created_by ? (
                        typeof item.created_by === 'object' ? 
                          item.created_by.username || item.created_by.name || 'N/A' :
                          item.created_by
                      ) : 'N/A'}
                    </td>
                    <td className="px-4 py-2">
                      <span className={`px-2 py-1 rounded-full text-xs font-medium
                        ${item.status === 'Unpainted' && 'bg-yellow-100 text-yellow-800'}
                        ${item.status === 'Painted' && 'bg-green-100 text-green-800'}
                        ${item.status === 'Stickered' && 'bg-blue-100 text-blue-800'}
                        ${item.status === 'Expired' && 'bg-red-100 text-red-800'}
                      `}>
                        {item.status}
                      </span>
                    </td>
                    <td className="px-4 py-2">
                      <div className="flex items-center space-x-4">
                        <Eye 
                          size={16} 
                          className="text-blue-500 cursor-pointer hover:text-blue-700" 
                          onClick={() => {/* handle see action */}}
                        />
                        <Printer 
                          size={16} 
                          className="text-green-500 cursor-pointer hover:text-green-700" 
                          onClick={() => handlePrint(item.code)}
                        />
                        <Trash2 
                        size={16} 
                        className="text-red-500 cursor-pointer hover:text-red-700" 
                        onClick={() => handleDeleteClick(item.code)}
                      />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
          </table>

          {/* Pagination and Limiting */}
          {/* Pagination and Limiting */}
<div className="flex justify-between items-center mt-4">
  <div className="flex items-center space-x-2">
    <span>Show:</span>
    <select 
      className="border rounded px-2 py-1"
      value={itemsPerPage}
      onChange={handleLimitChange}
    >
      <option value={5}>5</option>
      <option value={10}>10</option>
      <option value={25}>25</option>
      <option value={50}>50</option>
      <option value={filteredData.length}>All</option>
    </select>
    <span>entries</span>
  </div>

  <div className="flex items-center space-x-2">
    <button 
      className="border rounded px-3 py-1 hover:bg-gray-100"
      onClick={() => handlePageChange(currentPage - 1)}
      disabled={currentPage === 1}
    >
      &lt;
    </button>

    {[...Array(totalPages)].map((_, index) => (
      <button
        key={index + 1}
        className={`px-3 py-1 rounded ${
          currentPage === index + 1 
            ? 'bg-blue-500 text-white' 
            : 'border hover:bg-gray-100'
        }`}
        onClick={() => handlePageChange(index + 1)}
      >
        {index + 1}
      </button>
    ))}

    <button 
      className="border rounded px-3 py-1 hover:bg-gray-100"
      onClick={() => handlePageChange(currentPage + 1)}
      disabled={currentPage === totalPages}
    >
      &gt;
    </button>
  </div>

  <div className="text-gray-600">
    Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, filteredData.length)} of {filteredData.length} entries
  </div>
</div>
        </div>
        {showDeleteModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
    <div className="bg-white p-6 rounded-lg shadow-xl">
      <h3 className="text-lg font-semibold mb-4">Confirm Delete</h3>
      <p className="mb-6">Are you sure you want to delete QR code: {qrToDelete}?</p>
      <div className="flex justify-end space-x-3">
        <button 
          className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
          onClick={() => {
            setShowDeleteModal(false);
            setQrToDelete(null);
          }}
        >
          Cancel
        </button>
        <button 
          className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          onClick={handleDeleteConfirm}
        >
          Delete
        </button>
      </div>
    </div>
  </div>
)}

{showStatusModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
      <h3 className="text-lg font-semibold mb-4">Confirm Status Change</h3>
      <p className="mb-6">
        Are you sure you want to change the status of {selectedItems.length} selected items to {pendingStatus}?
      </p>
      <div className="flex justify-end space-x-3">
        <button 
          className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
          onClick={() => {
            setShowStatusModal(false);
            setPendingStatus(null);
          }}
        >
          Cancel
        </button>
        <button 
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          onClick={handleStatusChangeConfirm}
        >
          Confirm
        </button>
      </div>
    </div>
  </div>
)}
      </div>
    </>
  );
};

export default QRPrint;

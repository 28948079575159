import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Undo2 } from 'lucide-react';

const AdminUserEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = location.state;
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    name: userData?.name || '',
    email: userData?.email || '',
    phoneNumber: userData?.phoneNumber || '',
    password: '',
    role: userData?.role || '',
    designation: userData?.designation || ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/update-user/${userData.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to update user');
      }

      navigate(-1);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBack = () => navigate(-1);

  return (
    <>
      <div className="flex justify-between items-center mb-2 ml-4">
        <h1 className="text-2xl font-bold">Edit Admin User</h1>
        <button 
          className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 mr-5"
          onClick={handleBack}
        >
          <Undo2 size={20} />
        </button>
      </div>
      <p className="text-gray-500 text-sm ml-4">
        User Management <span className="text-gray-400">&gt; Edit Admin User</span>
      </p>
      <div className="bg-white rounded-2xl shadow-md p-6 m-5">
        {error && (
          <div className="mb-4 p-3 bg-red-100 text-red-600 rounded-lg">
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label className="text-gray-700">Name</label>
              <input 
                type="text" 
                name="name" 
                value={formData.name} 
                onChange={handleChange} 
                className="border rounded px-2 py-1"
                required
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700">Email</label>
              <input 
                type="email" 
                name="email" 
                value={formData.email} 
                onChange={handleChange} 
                className="border rounded px-2 py-1"
                required
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700">Phone Number</label>
              <input 
                type="tel" 
                name="phoneNumber" 
                value={formData.phoneNumber} 
                onChange={handleChange} 
                className="border rounded px-2 py-1"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700">Role</label>
              <select 
                name="role" 
                value={formData.role} 
                onChange={handleChange}
                className="border rounded px-2 py-1"
                required
              >
                <option value="">Select Role</option>
                <option value="SuperAdmin">Super Admin</option>
                <option value="SupportStuff">Support Staff</option>
                <option value="ContentManager">Content Manager</option>
              </select>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700">Designation</label>
              <input 
                type="text" 
                name="designation" 
                value={formData.designation} 
                onChange={handleChange} 
                className="border rounded px-2 py-1"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700">New Password (Optional)</label>
              <input 
                type="password" 
                name="password" 
                value={formData.password} 
                onChange={handleChange} 
                className="border rounded px-2 py-1"
                minLength="6"
              />
            </div>
          </div>
          <div className="flex justify-end">
            <button 
              type="submit" 
              className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 disabled:bg-blue-300"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AdminUserEdit;
import React, { useState, useEffect, useRef } from 'react';
import { Search, Bell } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import NotificationPanel from './NotificationPanel';
import alternativeAdminFace from '../../images/alternative_admin_image.png';
import DropdownPanel from './DropdownPanel';
import FeedbackModal from './../Feedback/FeedbackModal';

const userData = JSON.parse(localStorage.getItem('user')) || {};
const { name = 'Guest', role = 'Unknown' } = userData;

const Navbar = () => {
  const [profileImage, setProfileImage] = useState(alternativeAdminFace);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const notificationRef = useRef(null);
  const dropdownRef = useRef(null);

  const [selectedFeedback, setSelectedFeedback] = useState(null);

  const fetchNotifications = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/view-feedback`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) throw new Error('Failed to fetch notifications');

      const data = await response.json();
      const formattedNotifications = data.map(feedback => ({
        id: feedback._id,
        title: `Feedback from ${feedback.parentName}`,
        content: feedback.message,
        checked: feedback.status === 'Checked' || feedback.status === 'Issue',
        time: new Date(feedback.created_at).toISOString(),
        parentEmail: feedback.parentEmail,
        status: feedback.status
      }));

      setNotifications(formattedNotifications);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
    // Poll for new notifications every 5 minutes
    const interval = setInterval(fetchNotifications, 300000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/profile`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          }
        });

        if (!response.ok) throw new Error('Failed to fetch profile');

        const data = await response.json();
        if (data.success && data.data.profilePicture) {
          setProfileImage(data.data.profilePicture);
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
        setProfileImage(alternativeAdminFace); // Fallback to default image
      }
    };

    fetchProfileImage();
  }, []);

  const fetchFeedbackDetails = async (id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/view-feedback/${id}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
      });

      if (!response.ok) throw new Error('Failed to fetch feedback details');

      const feedback = await response.json();
      setSelectedFeedback({
        feedbackId: feedback._id,
        parentName: feedback.parentName,
        email: feedback.parentEmail,
        mobileNumber: feedback.mobileNumber || 'N/A',
        message: feedback.message,
        date: new Date(feedback.created_at).toLocaleDateString(),
        status: feedback.status
      });
    } catch (error) {
      console.error('Error fetching feedback details:', error);
    }
  };

  const handleSearchClick = () => {
    navigate('/admin/search-user');
  };

  const handleBellClick = () => {
    setShowNotifications(!showNotifications);
  };

  const handleLogoClick = () => {

    setShowDropdown(!showDropdown);
  };

  const handleClickOutside = (event) => {
    if (notificationRef.current && !notificationRef.current.contains(event.target)) {
      setShowNotifications(false);
    }
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (showNotifications || showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showNotifications, showDropdown]);

  const uncheckedCount = notifications.filter(
    notification => !notification.checked
  ).length;

  const handleCloseModal = () => {
    setSelectedFeedback(null);
  };

  // Add function to handle status update
  const handleStatusUpdate = () => {
    fetchNotifications(); // Refresh notifications after status update
  };

  return (
    <div className="rounded-2xl relative bg-white shadow-md p-4 right-0 top-0">
      <div className="flex items-center justify-between mx-4"> 
        <div className="flex items-center">
         
        </div>
        <div className="flex items-center space-x-4">
          <div className="relative flex items-center">
            <Search size={24} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-600" />
            <input
              type="text"
              placeholder="Search..."
              className="border rounded-xl pl-12 pr-4 py-2 w-48 bg-gray-50"
              onClick={handleSearchClick}
              readOnly
            />
            <div className="relative mx-4">
              <Bell size={24} className="ml-3 text-blue-600 hover:text-blue-500 cursor-pointer" onClick={handleBellClick} />
              {uncheckedCount > 0 && (
                <span className="absolute top-[-10px] right-[-5px] inline-flex items-center justify-center px-1.5 py-0.5 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                  {uncheckedCount}
                </span>
              )}
            </div>
          </div>
          
          <span className="mr-3 text-sm font-semibold">
            {name}
            <br />
            <span className="text-xs font-normal">{role}</span>
          </span>
          <img 
            src={profileImage} 
            alt="Profile" 
            className="h-10 w-10 rounded-lg cursor-pointer object-cover" 
            onClick={handleLogoClick}
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop
              e.target.src = alternativeAdminFace; // Fallback image
            }}
          />
        </div>
        {showNotifications && (
          <div ref={notificationRef} className="absolute top-16 right-4 z-50">
            <NotificationPanel 
              notifications={notifications}
              loading={loading}
              onNotificationClick={(id) => {
                navigate(`/admin/feedback/${id}`);
                setShowNotifications(false);
              }}
            />
          </div>
        )}
        {showDropdown && (
          <div ref={dropdownRef} className="absolute top-16 right-4 z-50">
            <DropdownPanel />
          </div>
        )}
      </div>
      {showNotifications && (
        <div ref={notificationRef} className="absolute top-16 right-4 z-50">
          <NotificationPanel 
            notifications={notifications}
            loading={loading}
            onNotificationClick={(id) => {
              fetchFeedbackDetails(id);
              setShowNotifications(false);
            }}
          />
        </div>
      )}
      
      {selectedFeedback && (
        <FeedbackModal 
          feedback={selectedFeedback}
          onClose={handleCloseModal}
          onStatusUpdate={handleStatusUpdate}
        />
      )}
    </div>
  );
};

export default Navbar;
import React, { useState, useEffect } from 'react';
import { Eye, Edit, Trash2 } from 'lucide-react';

const ActiveQR = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [qrCodes, setQrCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [qrToDelete, setQrToDelete] = useState(null);

  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    fetchQRCodes();
  }, []);

  const fetchQRCodes = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/view-all-qr`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch QR codes');
      }

      const data = await response.json();
      setQrCodes(data);
      setError(null);
    } catch (err) {
      setError('Failed to load QR codes');
      console.error('Error fetching QR codes:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredData = qrCodes.filter((item) => {
    return item.code?.toLowerCase().includes(searchQuery.toLowerCase()) || 
           item.created_at?.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  if (isLoading) {
    return <div className="text-center p-4">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center p-4">{error}</div>;
  }

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };
  
  
  const sortedData = [...filteredData].sort((a, b) => {
    if (!sortField) return 0;
  
    let aValue = a[sortField];
    let bValue = b[sortField];
  
    
    switch (sortField) {
      case 'created_at':
      case 'activated_at':
        aValue = new Date(aValue || 0).getTime();
        bValue = new Date(bValue || 0).getTime();
        break;
      case 'code':
      case 'DeviceId':
        aValue = aValue || '';
        bValue = bValue || '';
        break;
    }
  
    if (sortDirection === 'asc') {
      return aValue > bValue ? 1 : -1;
    } else {
      return aValue < bValue ? 1 : -1;
    }
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(Math.min(Math.max(1, pageNumber), totalPages));
  };
  
  const handleLimitChange = (event) => {
    const newLimit = parseInt(event.target.value);
    setItemsPerPage(newLimit);
    setCurrentPage(1);
  };

  const handleOpenModal = (item) => {
    // TODO: Implement show modal logic
    console.log('Show details for:', item);
  };
  
  const handleEdit = (item) => {
    // TODO: Implement edit logic
    console.log('Edit item:', item);
  };
  
  const handleDelete = (item) => {
    // TODO: Implement delete logic
    console.log('Delete item:', item);
  };

  const handleDeleteClick = (item) => {
    setQrToDelete(item.code);
    setShowDeleteModal(true);
  };
  
  const handleDeleteConfirm = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/delete-qr/${qrToDelete}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete QR code');
      }
  
      setQrCodes(qrCodes.filter(qr => qr.code !== qrToDelete));
      setShowDeleteModal(false);
      setQrToDelete(null);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <>
      <div className="flex justify-between items-center mb-2 ml-4">
        <h1 className="text-2xl font-bold">QR Code</h1>
      </div>
      <p className="text-gray-500 text-sm ml-4">
        QR Management <span className="text-gray-400">&gt; QR Content</span>
      </p>
      <div className="bg-white rounded-2xl shadow-md p-6 m-5">
        {/* Header */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-gray-700">Active QR</h2>
          <div className="flex items-center space-x-2">
            <input 
              type="text" 
              placeholder="Search..." 
              value={searchQuery}
              onChange={handleSearchChange}
              className="border rounded px-2 py-1"
            />
            <button className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600">
              Filter
            </button>
          </div>
        </div>

        {/* Table */}
        <div className="overflow-x-auto">
        <table className="min-w-full table-auto">
        <thead>
          <tr className="bg-blue-100 text-blue-600">
            <th className="px-4 py-2 text-left">#</th>
            <th 
              className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
              onClick={() => handleSort('code')}
            >
              QR Code {sortField === 'code' && (sortDirection === 'asc' ? '↑' : '↓')}
            </th>
            <th 
              className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
              onClick={() => handleSort('created_at')}
            >
              Creation Date {sortField === 'created_at' && (sortDirection === 'asc' ? '↑' : '↓')}
            </th>
            <th 
              className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
              onClick={() => handleSort('activated_at')}
            >
              Activation Date {sortField === 'activated_at' && (sortDirection === 'asc' ? '↑' : '↓')}
            </th>
            <th 
              className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
              onClick={() => handleSort('DeviceId')}
            >
              Device ID {sortField === 'DeviceId' && (sortDirection === 'asc' ? '↑' : '↓')}
            </th>
            <th className="px-4 py-2 text-left">Action</th>
          </tr>
        </thead>
          <tbody className="text-gray-600">
            {currentItems.map((item, index) => (
              <tr key={item._id} className="border-b">
                <td className="px-4 py-2">{index + 1}</td>
                <td className="px-4 py-2">{item.code}</td>
                <td className="px-4 py-2">{new Date(item.created_at).toLocaleString()}</td>
                <td className="px-4 py-2">
                  {item.activated_at ? new Date(item.activated_at).toLocaleString() : 'Not Activated'}
                </td>
                <td className="px-4 py-2">{item.DeviceId || 'N/A'}</td>
                
                <td className="px-4 py-2">
  <div className="flex items-center space-x-4">
    <Eye 
      size={16} 
      className="text-blue-500 cursor-pointer hover:text-blue-700" 
      onClick={() => handleOpenModal(item)}
    />
    <Edit 
      size={16} 
      className="text-green-500 cursor-pointer hover:text-green-700" 
      onClick={() => handleEdit(item)}
    />
    <Trash2 
      size={16} 
      className="text-red-500 cursor-pointer hover:text-red-700" 
      onClick={() => handleDeleteClick(item)}
    />
  </div>
</td>
              </tr>
            ))}
          </tbody>
        </table>

          {/* Pagination and Sorting */}
          <div className="flex justify-between items-center mt-4">
            <div className="flex items-center space-x-2">
              <span>Show:</span>
              <select 
                className="border rounded px-2 py-1"
                value={itemsPerPage}
                onChange={handleLimitChange}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={filteredData.length}>All</option>
              </select>
              <span>entries</span>
            </div>

            <div className="flex items-center space-x-2">
              <button 
                className="border rounded px-3 py-1 hover:bg-gray-100"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                &lt;
              </button>

              {[...Array(totalPages)].map((_, index) => (
                <button
                  key={index + 1}
                  className={`px-3 py-1 rounded ${
                    currentPage === index + 1 
                      ? 'bg-blue-500 text-white' 
                      : 'border hover:bg-gray-100'
                  }`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              ))}

              <button 
                className="border rounded px-3 py-1 hover:bg-gray-100"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                &gt;
              </button>
            </div>

            <div className="text-gray-600">
              Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, filteredData.length)} of {filteredData.length} entries
            </div>
          </div>
        </div>
        {showDeleteModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg shadow-xl">
              <h3 className="text-lg font-semibold mb-4">Confirm Delete</h3>
              <p className="mb-6">Are you sure you want to delete QR code: {qrToDelete}?</p>
              <div className="flex justify-end space-x-3">
                <button 
                  className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
                  onClick={() => {
                    setShowDeleteModal(false);
                    setQrToDelete(null);
                  }}
                >
                  Cancel
                </button>
                <button 
                  className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                  onClick={handleDeleteConfirm}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ActiveQR;
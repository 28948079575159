import React, { useState, useEffect } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { jsPDF } from 'jspdf';
import QRCode from 'qrcode';

const QRManagement = () => {
  const [qrType, setQrType] = useState('');
  const [bulkQuantity, setBulkQuantity] = useState(0);
  const [bulkCodes, setBulkCodes] = useState([]);
  const [singleCode, setSingleCode] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);

  const [selectedProductDetails, setSelectedProductDetails] = useState(null);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/view-products`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch products');
      }
  
      const result = await response.json();
      
      // Access the products from the data property
      if (result.success && Array.isArray(result.data)) {
        setProducts(result.data);
      } else {
        throw new Error('Invalid data structure received from server');
      }
      
      setError(null);
    } catch (err) {
      setError('Failed to load products');
      console.error('Error fetching products:', err);
      setProducts([]); 
    } finally {
      setIsLoading(false);
    }
  };

  const generateSingleCode = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/generate-qr`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          product: selectedProduct
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to generate QR code');
      }

      const data = await response.json();
      setSingleCode(data.code);
    } catch (err) {
      setError(err.message);
      console.error('Error generating QR code:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const generateBulkCodes = async () => {
    try {
      setIsLoading(true);
      setError(null);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/generate-bulk-qr`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          product: selectedProduct,
          amount: bulkQuantity
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to generate bulk QR codes');
      }

      const data = await response.json();
      setBulkCodes(data.codes);
    } catch (err) {
      setError(err.message);
      console.error('Error generating bulk QR codes:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleProductSelect = (product) => {
    setSelectedProduct(product._id);
    setSelectedProductDetails(product);
  };

  const generateTimestamp = () => {
    const now = new Date();
    return `${now.getFullYear()}${String(now.getMonth() + 1).padStart(2, '0')}${String(now.getDate()).padStart(2, '0')}_${String(now.getHours()).padStart(2, '0')}${String(now.getMinutes()).padStart(2, '0')}${String(now.getSeconds()).padStart(2, '0')}`;
  };

  const downloadPDF = async () => {
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageWidth = 220; // A4 width
    const pageHeight = 297; // A4 height
    const qrSize = 40;
    const margin = 6;
    const qrPerRow = Math.floor((pageWidth - 2 * margin) / (qrSize + margin));
    const qrPerColumn = Math.floor((pageHeight - 2 * margin) / (qrSize + margin));
    const codesPerPage = qrPerRow * qrPerColumn;
  
    const promises = bulkCodes.map((code) => {
      return generateQRWithLogo(code, qrSize * 3.78); // Maintain consistent DPI
    });
  
    const imgDataArray = await Promise.all(promises);
    imgDataArray.forEach((imgData, index) => {
      const x = 7 +  margin + (index % qrPerRow) * (qrSize + margin);
      const y = 4 + margin + Math.floor((index % codesPerPage) / qrPerRow) * (qrSize + margin);
  
      pdf.addImage(imgData, 'PNG', x, y, qrSize, qrSize);
  
      if ((index + 1) % codesPerPage === 0 && index + 1 < bulkCodes.length) {
        pdf.addPage();
      }
    });
  
    const timestamp = generateTimestamp();
    pdf.save(`qr-codes_${timestamp}.pdf`);
  };

  const generateQRWithLogo = async (code, size) => {
    try {
      // Generate QR code without logo first
      const qrDataUrl = await QRCode.toDataURL(code, {
        width: size,
        height: size,
        margin: 1,
        errorCorrectionLevel: 'H',
        color: {
          dark: '#000000',
          light: '#ffffff',
        },
        maskPattern: 0,
        version: 3,
      });
  
      // Create a new promise for image processing
      return new Promise((resolve) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const qrImage = new Image();
        const logoImage = new Image();
  
        // Set cross-origin attributes
        qrImage.crossOrigin = 'anonymous';
        logoImage.crossOrigin = 'anonymous';
  
        qrImage.onload = () => {
          canvas.width = size;
          canvas.height = size;
          ctx.drawImage(qrImage, 0, 0, size, size);
  
          // Only add logo if it exists
          if (selectedProductDetails?.productLogo) {
            logoImage.onload = () => {
              const logoSize = size * 0.2;
              const logoX = (size - logoSize) / 2;
              const logoY = (size - logoSize) / 2;
  
              ctx.fillStyle = '#FFFFFF';
              ctx.fillRect(logoX - 2, logoY - 2, logoSize + 4, logoSize + 4);
              ctx.drawImage(logoImage, logoX, logoY, logoSize, logoSize);
              resolve(canvas.toDataURL('image/png'));
            };
  
            logoImage.onerror = () => {
              // If logo fails to load, return QR code without logo
              resolve(canvas.toDataURL('image/png'));
            };
  
            logoImage.src = selectedProductDetails.productLogo;
          } else {
            // If no logo, return just the QR code
            resolve(canvas.toDataURL('image/png'));
          }
        };
  
        qrImage.onerror = () => {
          console.error('Failed to load QR code');
          resolve(null);
        };
  
        qrImage.src = qrDataUrl;
      });
    } catch (error) {
      console.error('Error generating QR code:', error);
      return null;
    }
  };

  const downloadSinglePDF = async () => {
    const pdf = new jsPDF('p', 'mm', 'a4');
    const qrSize = 40;
    const margin = 10;
  
    const imgData = await generateQRWithLogo(singleCode, qrSize * 3.78);
    pdf.addImage(imgData, 'PNG', margin, margin, qrSize, qrSize);
  
    const timestamp = generateTimestamp();
    pdf.save(`single-qr-code_${timestamp}.pdf`);
  };

  const printSingleQR = async () => {
    const printWindow = window.open('', '_blank');
    const qrSize = 150;
    const margin = 10;
    
    const imgData = await generateQRWithLogo(singleCode, qrSize);
    
    const htmlContent = `
      <html>
        <head>
          <title>Print QR Code</title>
          <style>
            .qr-container {
              padding: ${margin}px;
            }
            @media print {
              @page {
                size: A4;
                margin: 10mm;
              }
            }
          </style>
        </head>
        <body>
          <div class="qr-container">
            <img src="${imgData}" width="${qrSize}" height="${qrSize}" />
          </div>
        </body>
      </html>
    `;
  
    printWindow.document.write(htmlContent);
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.print();
    };
  };

  const printBulkQR = async () => {
    const printWindow = window.open('', '_blank');
    const qrSize = 150;
    const margin = 10;
    
    // Create promises for all QR codes
    const promises = bulkCodes.map((code) => generateQRWithLogo(code, qrSize));
    const qrDataArray = await Promise.all(promises);
    
    // Create HTML content with 4-column grid layout
    const htmlContent = `
      <html>
        <head>
          <title>Print QR Codes</title>
          <style>
            .qr-grid {
              display: grid;
              grid-template-columns: repeat(4, 1fr); /* Changed from 3 to 4 */
              gap: ${margin}px;
              padding: ${margin}px;
            }
            .qr-item {
              text-align: center;
            }
            @media print {
              @page {
                size: A4;
                margin: 10mm;
              }
            }
          </style>
        </head>
        <body>
          <div class="qr-grid">
            ${qrDataArray.map(data => `
              <div class="qr-item">
                <img src="${data}" width="${qrSize}" height="${qrSize}" />
              </div>
            `).join('')}
          </div>
        </body>
      </html>
    `;
  
    printWindow.document.write(htmlContent);
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.print();
    };
};

  const renderQRCodeWithLogo = (value) => (
    <div className="relative inline-block">
      <QRCodeSVG
        value={value}
        size={150}
        level="H"
        imageSettings={{
          src: selectedProductDetails?.productLogo,
          height: 24,
          width: 24,
          excavate: true
        }}
      />
    </div>
  );

  return (
    <>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Create New QR Code</h1>
      </div>
      {error && (
        <div className="bg-red-100 text-red-700 p-3 rounded mb-4 mx-5">
          {error}
        </div>
      )}
      <p className="text-gray-500 text-sm">
        QR Management <span className="text-gray-400">&gt; Create New QR Code</span>
      </p>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-4 mx-5">
        <div className="lg:col-span-1 bg-white rounded-2xl shadow-lg p-6">
        <div className="mb-4">
        <h4 className="text-md font-semibold text-gray-700 mb-2">Select Product</h4>
        <div className="grid grid-cols-2 gap-4">
        {products.map(product => (
          <label key={product._id} className="flex items-center">
            <input
              type="radio"
              name="product"
              value={product._id}
              onChange={() => handleProductSelect(product)}
              checked={selectedProduct === product._id}
              required
            />
            <span className="ml-2">{product.productName}</span>
          </label>
        ))}
        </div>
      </div>
          <h3 className="text-lg font-semibold text-gray-700 mb-4">Generate New QR Code</h3>

          <div className="flex space-x-4 mb-4">
            <label className="flex items-center">
              <input
                type="radio"
                name="qrType"
                value="single"
                onChange={() => setQrType('single')}
                checked={qrType === 'single'}
              />
              <span className="ml-2">Generate New QR</span>
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                name="qrType"
                value="bulk"
                onChange={() => setQrType('bulk')}
                checked={qrType === 'bulk'}
              />
              <span className="ml-2">Generate New QR Bulk</span>
            </label>
          </div>

          {qrType === 'bulk' && (
            <div className="flex items-center mb-4">
              <label className="mr-2">QR Quantity:</label>
              <input
                type="number"
                value={bulkQuantity}
                onChange={(e) => setBulkQuantity(Number(e.target.value))}
                className="border border-gray-300 rounded p-2 w-full"
                placeholder="Enter quantity"
                min="1"
              />
              <button
                onClick={generateBulkCodes}
                className="ml-4 bg-blue-600 text-white rounded p-2"
                disabled={!selectedProduct || isLoading}
              >
                {isLoading ? 'Generating...' : 'Generate'}
              </button>
            </div>
          )}

          {qrType === 'single' && (
            <div className="flex items-center mb-4">
              <button
                onClick={generateSingleCode}
                className="bg-blue-600 text-white rounded p-2"
                disabled={!selectedProduct || isLoading}
              >
                {isLoading ? 'Generating...' : 'Generate Single QR Code'}
              </button>
            </div>
          )}
        </div>

        <div className="lg:col-span-1 bg-white rounded-2xl shadow-lg p-6 flex flex-col items-center">
          {qrType === 'bulk' && bulkCodes.length > 0 ? (
            <div className="grid grid-cols-2 gap-4">
              {bulkCodes.slice(0, 4).map((code, index) => (
                <div key={index} className="relative inline-block">
                  <QRCodeSVG 
                    value={code} 
                    size={82}
                    level="H"
                    imageSettings={{
                      src: selectedProductDetails?.productLogo,
                      height: 16,
                      width: 16,
                      excavate: true
                    }}
                  />
                </div>
              ))}
            </div>
          ) : qrType === 'single' && singleCode ? (
            <>
              {renderQRCodeWithLogo(singleCode)}
              <div className="flex justify-center space-x-4 mt-4">
                <button onClick={downloadSinglePDF} className="bg-blue-600 text-white rounded px-4 py-2">
                  Download PDF
                </button>
                <button onClick={printSingleQR} className="bg-blue-600 text-white rounded px-4 py-2">
                  Print
                </button>
              </div>
            </>
          ) : (
            <p className="text-center text-gray-500">Generate QR codes to see them here.</p>
          )}

          {qrType === 'bulk' && bulkCodes.length > 0 && (
            <div className="flex justify-center space-x-4 mt-4">
              <button onClick={downloadPDF} className="bg-blue-600 text-white rounded px-4 py-2">
                Download PDF
              </button>
              <button onClick={printBulkQR} className="bg-blue-600 text-white rounded px-4 py-2">Print</button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default QRManagement;

import React from 'react';

const KidsProfileModal = ({ kid, onClose }) => {
  if (!kid) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold text-gray-800">Kids Profile Details</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          
          <div className="flex flex-col items-center mb-6">
            <img 
              src={kid.avatar || "/api/placeholder/100/100"} 
              alt={kid.name} 
              className="w-24 h-24 rounded-full mb-2" 
            />
          </div>
          
          <div className="grid grid-cols-2 gap-4 mb-6">
            <div className="text-left">
              <p className="text-sm text-gray-600 my-3">Child Name</p>
              <p className="text-sm text-gray-600 my-3">Age</p>
              <p className="text-sm text-gray-600 my-3">Gender</p>
              <p className="text-sm text-gray-600 my-3">Parent Name</p>
              <p className="text-sm text-gray-600 my-3">Parent Contact</p>
              <p className="text-sm text-gray-600 my-3">Voice File</p>
              <p className="text-sm text-gray-600 my-3">Voice Uploaded By</p>
              <p className="text-sm text-gray-600 my-3">Current Score</p>
            </div>
            <div className="text-right">
              <p className="text-sm text-gray-800 my-3">{kid.name}</p>
              <p className="text-sm text-gray-800 my-3">{kid.age}</p>
              <p className="text-sm text-gray-800 my-3">{kid.gender}</p>
              <p className="text-sm text-gray-800 my-3">{kid.parent.name}</p>
              <p className="text-sm text-gray-800 my-3">
                {kid.parent.phone || kid.parent.email}
              </p>
              <p className="text-sm text-gray-800 my-3">
                {kid.voiceFile ? (
                  <a href={kid.voiceFile} className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                    Listen
                  </a>
                ) : 'Not uploaded'}
              </p>
              <p className="text-sm text-gray-800 my-3">{kid.voiceUploader}</p>
              <p className="text-sm text-gray-800 my-3">{kid.score || 0}</p>
            </div>
          </div>
          
          <div className="border border-gray-200 rounded-lg p-4">
            <h4 className="text-lg font-semibold mb-2">Learning Progress</h4>
            <div className="overflow-x-auto">
              <table className="min-w-full table-auto">
                <thead>
                  <tr className="bg-blue-100 text-blue-600">
                    <th className="px-4 py-2 text-left">Last Activity</th>
                    <th className="px-4 py-2 text-left">Current Lesson</th>
                    <th className="px-4 py-2 text-left">Progress</th>
                    <th className="px-4 py-2 text-left">Status</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600">
                  <tr className="border-b">
                    <td className="px-4 py-2">{kid.lastActivity}</td>
                    <td className="px-4 py-2">{kid.lastLesson}</td>
                    <td className="px-4 py-2">{kid.progress}</td>
                    <td className="px-4 py-2">
                      <span className={`px-2 py-1 rounded-full text-xs ${
                        kid.progress === '100%' 
                          ? 'bg-blue-100 text-blue-800' 
                          : 'bg-green-100 text-green-800'
                      }`}>
                        {kid.progress === '100%' ? 'Completed' : 'In Progress'}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KidsProfileModal;
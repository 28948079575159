const logout = () => {
    // Clear all authentication data
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    
    // Optional: Clear any other auth-related data
    localStorage.removeItem('permissions');
    sessionStorage.clear();
    
    // Redirect to login page
    window.location.href = '/';
  };
  
  export default logout;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown, ChevronRight, Home, Users, Mic, Bell, MessageSquare, User, Settings, LogOut, QrCode, SquareUserRound, Box } from 'lucide-react';
import logo from '../images/company_logo.png';
import logout from './Logout';

const Sidebar = () => {
  const [expanded, setExpanded] = useState('');
  const userRole = JSON.parse(localStorage.getItem('user'))?.role;

  const isSuperAdmin = () => userRole === 'SuperAdmin';

  const toggleExpand = (item) => {
    setExpanded(expanded === item ? '' : item);
  };

  return (
    <div className="w-64 h-screen bg-[#f9fafb] flex flex-col p-4 relative">
      <div className="flex items-center justify-center mb-12 mt-2">
        <img src={logo} alt="Logo" className="h-10" />
      </div>
      <nav className="flex-grow">
        <ul className="">
          {/* Dashboard - visible to all */}
          <li className="flex items-center px-4 py-2 cursor-pointer rounded-t-lg hover:bg-blue-200 hover:text-white mb-6">
            <span className="ml-5 mr-4 text-blue-600"><Home size={20} /></span>
            <Link to="dashboard" className="flex-grow">Dashboard</Link>
          </li>
          
          {/* User Account section - visible to all */}
          <li className={`flex items-center px-4 py-2 cursor-pointer rounded-t-lg hover:bg-blue-200 hover:text-white ${expanded === 'User Account' ? 'bg-[#8fd3fe] text-blue-700' : ''}`}
            onClick={() => toggleExpand('User Account')}>
            <span className="ml-5 mr-4 text-blue-600"><Users size={20} /></span>
            <span className="flex-grow">User Account</span>
            {expanded === 'User Account' ? <ChevronDown size={18} className="text-gray-700" /> : <ChevronRight size={18} className="text-blue-600" />}
          </li>
          {expanded === 'User Account' && (
            <ul className="space-y-1 bg-[#b5e2ff] p-2 mt-0 text-sm rounded-b-lg">
              <li className="ml-8 px-4 py-2 hover:bg-blue-200 hover:text-white rounded-lg">
                <Link to="parent-accounts">Parent Account</Link>
              </li>
              <li className="ml-8 px-4 py-2 hover:bg-blue-200 hover:text-white rounded-lg">
                <Link to="kids-profiles">Kids Profile</Link>
              </li>
              <li className="ml-8 px-4 py-2 hover:bg-blue-200 hover:text-white rounded-lg">
                <Link to="guest-users">Guest User</Link>
              </li>
            </ul>
          )}

          {/* QR Management section - partially restricted */}
          <li className={`flex items-center px-4 py-2 cursor-pointer rounded-t-lg hover:bg-blue-200 hover:text-white mt-6 ${expanded === 'QR Management' ? 'bg-[#8fd3fe] text-blue-700' : ''}`}
            onClick={() => toggleExpand('QR Management')}>
            <span className="ml-5 mr-4 text-blue-600"><QrCode size={20} /></span>
            <span className="flex-grow">QR Management</span>
            {expanded === 'QR Management' ? <ChevronDown size={18} className="text-gray-700" /> : <ChevronRight size={18} className="text-blue-600" />}
          </li>
          {expanded === 'QR Management' && (
            <ul className="space-y-1 bg-[#b5e2ff] p-2 mt-0 text-sm rounded-b-lg">
              {isSuperAdmin() && (
                <>
                  <li className="ml-8 px-4 py-2 hover:bg-blue-200 hover:text-white rounded-lg">
                    <Link to="qr-management">Generate QR</Link>
                  </li>
                  <li className="ml-8 px-4 py-2 hover:bg-blue-200 hover:text-white rounded-lg">
                    <Link to="qr-print">Print QR</Link>
                  </li>
                </>
              )}
              <li className="ml-8 px-4 py-2 hover:bg-blue-200 hover:text-white rounded-lg">
                <Link to="qr-active">QR Activation</Link>
              </li>
            </ul>
          )}

          {/* Users Management section - partially restricted */}
          <li className={`flex items-center px-4 py-2 cursor-pointer rounded-t-lg hover:bg-blue-200 hover:text-white mt-6 ${expanded === 'Users Management' ? 'bg-[#8fd3fe] text-blue-700' : ''}`}
            onClick={() => toggleExpand('Users Management')}>
            <span className="ml-5 mr-4 text-blue-600"><SquareUserRound size={20} /></span>
            <span className="flex-grow">Users Management</span>
            {expanded === 'Users Management' ? <ChevronDown size={18} className="text-gray-700" /> : <ChevronRight size={18} className="text-blue-600" />}
          </li>
          {expanded === 'Users Management' && (
            <ul className="space-y-1 bg-[#b5e2ff] p-2 mt-0 text-sm rounded-b-lg">
              <li className="ml-8 px-4 py-2 hover:bg-blue-200 hover:text-white rounded-lg">
                <Link to="admin-users">Admin Users</Link>
              </li>
            </ul>
          )}
          {/* Product section - restricted to SuperAdmin */}
          {isSuperAdmin() && (
            <>
              <li className={`flex items-center px-4 py-2 cursor-pointer rounded-t-lg hover:bg-blue-200 hover:text-white mt-6 ${expanded === 'Product' ? 'bg-[#8fd3fe] text-blue-700' : ''}`}
                onClick={() => toggleExpand('Product')}>
                <span className="ml-5 mr-4 text-blue-600"><Box size={20} /></span>
                <span className="flex-grow">Product</span>
                {expanded === 'Product' ? <ChevronDown size={18} className="text-gray-700" /> : <ChevronRight size={18} className="text-blue-600" />}
              </li>
              {expanded === 'Product' && (
                <ul className="space-y-1 bg-[#b5e2ff] p-2 mt-0 text-sm rounded-b-lg">
                  <li className="ml-8 px-4 py-2 hover:bg-blue-200 hover:text-white rounded-lg">
                    <Link to="product-list">Product List</Link>
                  </li>
                  <li className="ml-8 px-4 py-2 hover:bg-blue-200 hover:text-white rounded-lg">
                    <Link to="product-list/product-add">Product Add</Link>
                  </li>
                </ul>
              )}
            </>
          )}

          {/* Remaining items - visible to all */}
          <li className="flex items-center px-4 py-2 cursor-pointer rounded-t-lg hover:bg-blue-200 hover:text-white mt-6">
            <span className="ml-5 mr-4 text-blue-600"><Mic size={20} /></span>
            <Link to="voice-list" className="flex-grow">Voice Upload</Link>
          </li>
          <li className="flex items-center px-4 py-2 cursor-pointer rounded-t-lg hover:bg-blue-200 hover:text-white mt-6">
            <span className="ml-5 mr-4 text-blue-600"><MessageSquare size={20} /></span>
            <Link to="feedbacks" className="flex-grow">Feedback</Link>
          </li>
          <li className="flex items-center px-4 py-2 cursor-pointer rounded-t-lg hover:bg-blue-200 hover:text-white mt-6"
            onClick={logout}>
            <span className="ml-5 mr-4 text-blue-600"><LogOut size={20} /></span>
            <span className="flex-grow">LogOut</span>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;

import React, { useState, useEffect } from 'react';
import { Eye, Edit, Trash, CirclePlus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import ProductModal from './ProductModal';
import ProductStatsBoxes from './ProductStatsBoxes';

const ProductListTable = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);

  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/view-products`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch products');
        }

        const data = await response.json();
        setProducts(data.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleOpenModal = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  const handleEditProduct = (product) => {
    navigate('/admin/product-list/product-edit', {
      state: {
        id: product._id,
        productName: product.productName,
        productType: product.productType,
        productPrice: product.productPrice,
        productLogo: product.productLogo,
        productCover: product.productCover,
        about: product.about,
        totalPage: product.totalPage,
        launchDate: product.launchDate
      }
    });
  };

  const filteredData = products.filter((product) => {
    const matchesSearchQuery = product.productName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.productType.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesSearchQuery;
  });

  const sortedProducts = products
    .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    .slice(0, 4);

  if (loading) return <div className="text-center p-4">Loading...</div>;
  if (error) return <div className="text-center text-red-500 p-4">{error}</div>;

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };
  
  // Add sorting logic before table rendering
  const sortedData = [...filteredData].sort((a, b) => {
    if (!sortField) return 0;
  
    let aValue = a[sortField];
    let bValue = b[sortField];
  
    
    switch (sortField) {
      case 'createdAt':
      case 'launchDate':
        aValue = new Date(aValue || 0).getTime();
        bValue = new Date(bValue || 0).getTime();
        break;
      case 'productType':
        aValue = aValue?.toLowerCase() || '';
        bValue = bValue?.toLowerCase() || '';
        break;
      case 'activation':
        aValue = Number(aValue) || 0;
        bValue = Number(bValue) || 0;
        break;
    }
  
    if (sortDirection === 'asc') {
      return aValue > bValue ? 1 : -1;
    } else {
      return aValue < bValue ? 1 : -1;
    }
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Add handler functions
  const handlePageChange = (pageNumber) => {
    setCurrentPage(Math.min(Math.max(1, pageNumber), totalPages));
  };

  const handleLimitChange = (event) => {
    const newLimit = parseInt(event.target.value);
    setItemsPerPage(newLimit);
    setCurrentPage(1);
  };

  const handleDeleteClick = (product) => {
    setProductToDelete(product);
    setShowDeleteModal(true);
  };
  
  const handleDeleteConfirm = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/delete-product/${productToDelete._id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete product');
      }
  
      setProducts(products.filter(product => product._id !== productToDelete._id));
      setShowDeleteModal(false);
      setProductToDelete(null);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <>
      <div className="flex justify-between items-center mb-2 ml-4">
        <h1 className="text-2xl font-bold">Product List</h1>
        <button 
          className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 mr-5 flex items-center"
          onClick={() => navigate('product-add')}
        >
          <CirclePlus size={20} className="mr-2" />
          Add Product
        </button>
      </div>
      <ProductStatsBoxes products={sortedProducts} />
      <p className="text-gray-500 text-sm ml-4">
        Product <span className="text-gray-400">&gt; Product List</span>
      </p>
      <div className="bg-white rounded-2xl shadow-md p-6 m-5">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-gray-700">Product List</h2>
          <div className="flex items-center space-x-2">
            <input 
              type="text" 
              placeholder="Search..." 
              value={searchQuery}
              onChange={handleSearchChange}
              className="border rounded px-2 py-1"
            />
            <button className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600">
              Filter
            </button>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full table-auto">
          <thead>
            <tr className="bg-blue-100 text-blue-600">
              <th className="px-4 py-2 text-left">#</th>
              <th className="px-4 py-2 text-left">Product Name</th>
              <th className="px-4 py-2 text-left">Cover Photo</th>
              <th 
                className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
                onClick={() => handleSort('productType')}
              >
                Product Type {sortField === 'productType' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th 
                className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
                onClick={() => handleSort('createdAt')}
              >
                Entry Date {sortField === 'createdAt' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th 
                className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
                onClick={() => handleSort('launchDate')}
              >
                Launch Date {sortField === 'launchDate' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th 
                className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
                onClick={() => handleSort('activation')}
              >
                Activate {sortField === 'activation' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th className="px-4 py-2 text-left">Action</th>
            </tr>
          </thead>

            <tbody className="text-gray-600">
              {currentItems.map((product) => (
                <tr key={product._id} className="border-b">
                  <td className="px-4 py-2">{product._id.slice(-4)}</td>
                  <td className="px-4 py-2">{product.productName}</td>
                  <td className="px-4 py-2">
                    <img src={product.productCover} alt={product.productName} className="w-10 h-10 object-cover" />
                  </td>
                  <td className="px-4 py-2">{product.productType}</td>
                  <td className="px-4 py-2">{new Date(product.createdAt).toLocaleDateString()}</td>
                  <td className="px-4 py-2">{product.launchDate ? new Date(product.launchDate).toLocaleDateString() : 'N/A'}</td>
                  <td className="px-4 py-2">
                    <span className={`px-2 py-1 rounded-full text-xs `}>
                      {product.activation}
                    </span>
                  </td>
                  <td className="p-2">
                    <div className="flex space-x-2">
                      <Eye 
                        size={16} 
                        className="text-blue-500 cursor-pointer" 
                        onClick={() => handleOpenModal(product)} 
                      />
                      <Edit 
                        size={16} 
                        className="text-yellow-500 cursor-pointer" 
                        onClick={() => handleEditProduct(product)} 
                      />
                      <Trash 
                        size={16} 
                        className="text-red-500 cursor-pointer" 
                        onClick={() => handleDeleteClick(product)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="flex justify-between items-center mt-4">
            <div className="flex items-center space-x-2">
              <span>Show:</span>
              <select 
                className="border rounded px-2 py-1"
                value={itemsPerPage}
                onChange={handleLimitChange}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={filteredData.length}>All</option>
              </select>
              <span>entries</span>
            </div>

            <div className="flex items-center space-x-2">
              <button 
                className="border rounded px-3 py-1 hover:bg-gray-100"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                &lt;
              </button>

              {[...Array(totalPages)].map((_, index) => (
                <button
                  key={index + 1}
                  className={`px-3 py-1 rounded ${
                    currentPage === index + 1 
                      ? 'bg-blue-500 text-white' 
                      : 'border hover:bg-gray-100'
                  }`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              ))}

              <button 
                className="border rounded px-3 py-1 hover:bg-gray-100"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                &gt;
              </button>
            </div>

            <div className="text-gray-600">
              Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, filteredData.length)} of {filteredData.length} entries
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && <ProductModal product={selectedProduct} onClose={handleCloseModal} />}
      {showDeleteModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
    <div className="bg-white p-6 rounded-lg shadow-xl">
      <h3 className="text-lg font-semibold mb-4">Confirm Delete</h3>
      <p className="mb-6">Are you sure you want to delete product: {productToDelete?.productName}?</p>
      <div className="flex justify-end space-x-3">
        <button 
          className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
          onClick={() => {
            setShowDeleteModal(false);
            setProductToDelete(null);
          }}
        >
          Cancel
        </button>
        <button 
          className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          onClick={handleDeleteConfirm}
        >
          Delete
        </button>
      </div>
    </div>
  </div>
)}
    </>
  );
};

export default ProductListTable;
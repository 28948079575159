import React, { useState, useEffect } from 'react';
import { Eye, Edit, Trash2 } from 'lucide-react';
import KidsProfileModal from './../UserAccount/KidsProfile/KidsProfileModal';
import { useNavigate } from 'react-router-dom'; 

const NewKidsProfile = () => {
  const navigate = useNavigate();
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedKid, setSelectedKid] = useState(null);

  useEffect(() => {
    const fetchKidsProfiles = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/dashboard/get-new-kids`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch kids profiles');
        }

        const data = await response.json();
        setProfiles(data);
      } catch (err) {
        setError('Failed to load kids profiles');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchKidsProfiles();
  }, []);

  const handleOpenModal = (profile) => {
    const formattedKid = {
      name: profile.name,
      age: profile.age,
      gender: profile.gender,
      voiceFile: profile.voiceFile,
      parent: profile.parent || {},
      lastActivity: profile.lastActivity ? new Date(profile.lastActivity).toLocaleString() : 'N/A',
      lastLesson: profile.lastLevel || 'N/A',
      progress: `${profile.progress || 0}%`,
      voiceUploader: profile.voiceUploader
    };
    
    setSelectedKid(formattedKid);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedKid(null);
  };

  const handleEdit = (profile) => {
    navigate('/admin/kids-profiles/edit', {
      state: {
        id: profile.id,
        name: profile.name,
        age: profile.age,
        gender: profile.gender
      }
    });
  };

  if (loading) return <div className="text-center p-4">Loading...</div>;
  if (error) return <div className="text-center text-red-500 p-4">{error}</div>;

  return (
    <div className="bg-white rounded-2xl shadow-md p-6 m-5">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-gray-700">New Kids Profile</h2>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full table-auto">
          <thead>
            <tr className="bg-blue-100 text-blue-600">
              <th className="px-4 py-2 text-left">Kids Name</th>
              <th className="px-4 py-2 text-left">Age</th>
              <th className="px-4 py-2 text-left">Gender</th>
              <th className="px-4 py-2 text-left">Parent Account</th>
              <th className="px-4 py-2 text-left">Profile Create Time</th>
              <th className="px-4 py-2 text-left">Lesson Status</th>
              <th className="px-4 py-2 text-left">Action</th>
            </tr>
          </thead>

          <tbody className="text-gray-600">
            {profiles.map((profile, index) => (
              <tr key={index} className="border-b">
                <td className="px-4 py-2">{profile.name}</td>
                <td className="px-4 py-2">{profile.age}</td>
                <td className="px-4 py-2">{profile.gender}</td>
                <td className="px-4 py-2">{profile.parentName}</td>
                <td className="px-4 py-2">{new Date(profile.creationTime).toLocaleDateString()}</td>
                <td className="px-4 py-2">
                  <span className="bg-green-100 text-green-800 py-1 px-3 rounded-full text-xs">
                    {profile.lastLesson}
                  </span>
                </td>
                <td className="p-2">
                  <div className="flex space-x-2">
                    <Eye 
                      size={16} 
                      className="text-blue-500 cursor-pointer" 
                      onClick={() => handleOpenModal(profile)} 
                    />
                    <Edit 
                    size={16} 
                    className="text-green-500 cursor-pointer" 
                    onClick={() => handleEdit(profile)}
                  />
                    <Trash2 size={16} className="text-red-500 cursor-pointer" />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isModalOpen && <KidsProfileModal kid={selectedKid} onClose={handleCloseModal} />}
    </div>
  );
};

export default NewKidsProfile;
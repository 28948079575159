import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Undo2 } from 'lucide-react';

const ProfileUserEdit = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/profile`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          }
        });

        if (!response.ok) throw new Error('Failed to fetch profile');

        const data = await response.json();
        if (data.success) {
          setFormData({
            name: data.data.name || '',
            email: data.data.email || '',
            phoneNumber: data.data.phoneNumber || ''
          });
        }
      } catch (error) {
        setError('Failed to load profile data');
      }
    };

    fetchProfile();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/profile`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Update failed');
      }

      setSuccess('Profile updated successfully');
      setTimeout(() => navigate(-1), 2000);
    } catch (err) {
      setError(err.message || 'Failed to update profile');
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="flex justify-between items-center mb-2 ml-4">
        <h1 className="text-2xl font-bold">Edit Profile User</h1>
        <div className="flex space-x-2">
          <button 
            className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 mr-5"
            onClick={handleBack}
          >
            <Undo2 size={20} />
          </button>
        </div>
      </div>
      <p className="text-gray-500 text-sm ml-4">
        User Profile <span className="text-gray-400">&gt; Edit Profile User</span>
      </p>
      <div className="bg-white rounded-2xl shadow-md p-6 m-5">
        {error && <div className="text-red-500 mb-4">{error}</div>}
        {success && <div className="text-green-500 mb-4">{success}</div>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-3 gap-4">
            <div className="flex flex-col">
              <label className="text-gray-700">Name</label>
              <input 
                type="text" 
                name="name" 
                value={formData.name} 
                onChange={handleChange} 
                placeholder="Enter Full Name"
                className="border rounded px-2 py-1"
                required
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700">Email</label>
              <input 
                type="email" 
                name="email" 
                value={formData.email} 
                onChange={handleChange} 
                placeholder="Enter Email"
                className="border rounded px-2 py-1"
                required
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700">Phone Number</label>
              <input 
                type="tel" 
                name="phoneNumber" 
                value={formData.phoneNumber} 
                onChange={handleChange} 
                placeholder="Enter Phone Number"
                className="border rounded px-2 py-1"
                required
              />
            </div>
          </div>
          <div className="flex justify-end">
            <button 
              type="submit" 
              className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 disabled:opacity-50"
              disabled={loading}
            >
              {loading ? 'Updating...' : 'Submit'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ProfileUserEdit;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Undo2 } from 'lucide-react';

const AdminUserCreate = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    role: '',
    password: '',
    confirmPassword: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setError(null);
  };

  const validateForm = () => {
    if (!formData.name) {
      setError('Name is required');
      return false;
    }
    if (!formData.email) {
      setError('Email is required');
      return false;
    }
    if (!formData.password || formData.password.length < 6) {
      setError('Password must be at least 6 characters long');
      return false;
    }
    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/create-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          role: formData.role,
          password: formData.password
        })
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to create user');
      }

      alert('User created successfully!');
      navigate('/admin/admin-users');

    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="flex justify-between items-center mb-2 ml-4">
        <h1 className="text-2xl font-bold">Create Admin User</h1>
        <div className="flex space-x-2">
          <button 
            className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 mr-5"
            onClick={handleBack}
          >
            <Undo2 size={20} />
          </button>
        </div>
      </div>
      <p className="text-gray-500 text-sm ml-4">
        User Management <span className="text-gray-400">&gt; Create Admin User</span>
      </p>
      <div className="bg-white rounded-2xl shadow-md p-6 m-5">
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-3 gap-4">
            <div className="flex flex-col">
              <label className="text-gray-700">Name*</label>
              <input 
                type="text" 
                name="name" 
                value={formData.name} 
                onChange={handleChange} 
                placeholder="Enter Full Name"
                className="border rounded px-2 py-1"
                required
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700">Email*</label>
              <input 
                type="email" 
                name="email" 
                value={formData.email} 
                onChange={handleChange} 
                placeholder="Enter Email"
                className="border rounded px-2 py-1"
                required
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700">Role*</label>
              <select 
                name="role" 
                value={formData.role} 
                onChange={handleChange} 
                className="border rounded px-2 py-1"
                required
              >
                <option value="">Select Role</option>
                <option value="ContentManager">Content Manager</option>
                <option value="SupportStuff">Support Staff</option>
                <option value="SuperAdmin">Super Admin</option>
              </select>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label className="text-gray-700">Password*</label>
              <input 
                type="password" 
                name="password" 
                value={formData.password} 
                onChange={handleChange} 
                placeholder="Set Password"
                className="border rounded px-2 py-1"
                required
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700">Confirm Password*</label>
              <input 
                type="password" 
                name="confirmPassword" 
                value={formData.confirmPassword} 
                onChange={handleChange} 
                placeholder="Confirm Password"
                className="border rounded px-2 py-1"
                required
              />
            </div>
          </div>
          <div className="flex justify-end">
            <button 
              type="submit" 
              className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 disabled:bg-blue-300"
              disabled={loading}
            >
              {loading ? 'Creating...' : 'Submit'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AdminUserCreate;
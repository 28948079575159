import React, { useState } from 'react';
import logo from './../images/company_logo.png'

const Login = () => {
  const [formData, setFormData] = useState({
    identifier: '',
    password: '',
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        throw new Error(data.message || 'An error occurred');
      }
  
      // Store token in localStorage
      localStorage.setItem('token', data.token);
      
      // Store user info if needed
      localStorage.setItem('user', JSON.stringify(data.user));
  
      // Redirect based on role
      if (data.user.role === 'SuperAdmin') {
        window.location.href = '/admin/dashboard';
      }
      else if (data.user.role === 'SupportStuff') {
        window.location.href = '/admin/dashboard';
      }
      else if (data.user.role === 'ContentManager') {
        window.location.href = '/admin/dashboard';
      }
    } catch (err) {
      setError(err.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
<div className="flex h-screen bg-gray-100 justify-center items-center">
  {/* Main Container Box */}
  <div className="bg-white rounded-lg shadow-xl w-4/5 max-w-4xl h-3/5 flex overflow-hidden"> {/* Changed max-w-4xl to max-w-5xl and added h-4/5 */}
    {/* Left Section */}
    <div className="bg-blue-500 w-1/2 flex justify-center items-center relative">
    <img
      src={logo}
      alt="Logo"
      className="z-10 w-1/2 h-auto object-contain absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2"
    />
      <div className="absolute top-0 left-0 w-full h-full bg-blue-500 rounded-l-[50%]"></div>
    </div>

    {/* Right Section */}
    <div className="w-1/2 bg-white flex flex-col justify-center p-10">
      <h1 className="text-3xl font-bold mb-16">Hello Again!</h1>
      {error && <div className="mb-4 text-red-500">{error}</div>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="identifier" className="block mb-2 text-sm font-bold">Enter Email</label>
          <input
            type="text"
            id="identifier"
            name="identifier"
            value={formData.identifier}
            onChange={handleChange}
            placeholder="Email"
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="block mb-2 text-sm font-bold">Enter Password</label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Password"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
              required
            />
            <button 
              type="button" 
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-3 top-2 text-gray-500"
            >
              {showPassword ? '👁️' : '👁️‍🗨️'}
            </button>
          </div>
        </div>

        <div className="flex justify-between items-center mb-6">
          <label className="flex items-center">
            <input type="checkbox" className="mr-2" />
            <span className="text-sm">Remember me</span>
          </label>
          <a href="#" className="text-sm text-yellow-400 hover:underline">Forgot Password?</a>
        </div>

        <button
          type="submit"
          disabled={loading}
          className="w-full bg-blue-500 text-white py-3 rounded-lg hover:bg-blue-600 transition duration-300 disabled:bg-blue-300"
        >
          {loading ? 'Signing In...' : 'Sign In'}
        </button>
      </form>
    </div>
  </div>
</div>
  );
};

export default Login;

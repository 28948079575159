import React, { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";

Chart.register(ArcElement, Tooltip, Legend);

const DashboardBoxes = () => {
  const [userData, setUserData] = useState({
    Premium: 0,
    Registered: 0,
    Guest: 0
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [leaderboard, setLeaderboard] = useState([]);
  const [leaderboardLoading, setLeaderboardLoading] = useState(true);
  const [leaderboardError, setLeaderboardError] = useState(null);

  const [voiceStats, setVoiceStats] = useState({
    Total: 0,
    Boy: 0,
    Girl: 0
  });
  const [voiceStatsLoading, setVoiceStatsLoading] = useState(true);
  const [voiceStatsError, setVoiceStatsError] = useState(null);

  // Add new useEffect for leaderboard
  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/dashboard/get-leaderboard`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch leaderboard');
        }

        const data = await response.json();
        setLeaderboard(data);
      } catch (err) {
        setLeaderboardError(err.message);
        console.error('Error fetching leaderboard:', err);
      } finally {
        setLeaderboardLoading(false);
      }
    };

    fetchLeaderboard();
  }, []);

  useEffect(() => {
    const fetchVoiceStats = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/dashboard/get-voice-stats`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        });
  
        if (!response.ok) {
          throw new Error('Failed to fetch voice statistics');
        }
  
        const data = await response.json();
        setVoiceStats(data);
      } catch (err) {
        setVoiceStatsError(err.message);
        console.error('Error fetching voice stats:', err);
      } finally {
        setVoiceStatsLoading(false);
      }
    };
  
    fetchVoiceStats();
  }, []);

  useEffect(() => {
    const fetchUserStats = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/dashboard/get-user-stat`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user statistics');
        }

        const data = await response.json();
        setUserData(data);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching user stats:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserStats();
  }, []);

  // Calculate total and percentages
  const total = userData.Premium + userData.Registered + userData.Guest;
  const percentages = {
    Premium: ((userData.Premium / total) * 100).toFixed(1),
    Registered: ((userData.Registered / total) * 100).toFixed(1),
    Guest: ((userData.Guest / total) * 100).toFixed(1)
  };

  const data = {
    labels: ['Premium', 'Register', 'Guest'],
    datasets: [{
      label: 'Users',
      data: [percentages.Premium, percentages.Registered, percentages.Guest],
      backgroundColor: ['#1e40af', '#3b82f6', '#93c5fd'],
      hoverOffset: 4,
      borderWidth: 1,
    }],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: '80%',
    elements: {
      arc: {
        borderRadius: 3,
      },
    },
  };

  const renderLeaderboard = () => {
    if (leaderboardLoading) return <div className="text-center p-4">Loading...</div>;
    if (leaderboardError) return <div className="text-center text-red-500 p-4">{leaderboardError}</div>;
    if (leaderboard.length === 0) return <div className="text-center p-4">No data available</div>;

    return (
      <>
        {/* Table Header */}
        <div className="flex justify-between items-center bg-blue-50 text-blue-600 font-semibold p-2 rounded-t-md">
          <p>#</p>
          <p>Kids Name</p>
          <p>Points</p>
        </div>
        {/* Leaderboard Rows */}
        {leaderboard.map((entry) => (
          <div key={entry.position} className="flex justify-between items-center p-2 border-b border-gray-300">
            <p>{entry.position}</p>
            <p>{entry.name}</p>
            <p>{entry.score}</p>
          </div>
        ))}
      </>
    );
  };

  if (loading) return <div className="text-center p-4">Loading...</div>;
  if (error) return <div className="text-center text-red-500 p-4">{error}</div>;
  

  return (
    <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 mt-4 mx-5">
      <div className="lg:col-span-2 bg-white rounded-2xl shadow-md p-6">
        <h3 className="text-lg font-semibold text-gray-700 mb-4">Users</h3>
        <div className="flex justify-center items-center">
          <div className="relative">
            <div className="relative" style={{ width: '170px', height: '170px' }}>
              <Pie data={data} options={options} />
              <div className="absolute inset-0 flex flex-col items-center justify-center">
                <p className="text-sm text-gray-600">Total Users</p>
                <p className="text-2xl font-bold text-blue-600">{total}</p>
              </div>
            </div>
          </div>
          <div className="text-gray-600 ml-8 space-y-4">
            <p className="text-lg flex items-center">
              <span className="w-3 h-3 bg-[#1e40af] rounded-full mr-2"></span>
              Premium: <span className="text-blue-600 font-semibold ml-1">{percentages.Premium}%</span>
            </p>
            <p className="text-lg flex items-center">
              <span className="w-3 h-3 bg-[#3b82f6] rounded-full mr-2"></span>
              Register: <span className="text-blue-600 font-semibold ml-1">{percentages.Registered}%</span>
            </p>
            <p className="text-lg flex items-center">
              <span className="w-3 h-3 bg-[#93c5fd] rounded-full mr-2"></span>
              Guest: <span className="text-blue-600 font-semibold ml-1">{percentages.Guest}%</span>
            </p>
          </div>
        </div>
      </div>

      {/* Box 2: Voice Status */}
      <div className="bg-white rounded-2xl shadow-md p-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold text-gray-700 mr-3">Voice Status</h3>
          </div>
          {voiceStatsLoading ? (
            <div className="text-center p-4">Loading...</div>
          ) : voiceStatsError ? (
            <div className="text-center text-red-500 p-4">{voiceStatsError}</div>
          ) : (
            <div className="text-gray-600">
              <p className="mb-6 flex justify-between">
                <span>Total Pendings:</span>
                <span className="text-right mr-2">{voiceStats.Total}</span>
              </p>
              <p className="mb-2 flex justify-between">
                <span>Boys:</span>
                <span className="text-right mr-2">{voiceStats.Boy}</span>
              </p>
              <p className="mb-2 flex justify-between">
                <span>Girls:</span>
                <span className="text-right mr-2">{voiceStats.Girl}</span>
              </p>
            </div>
          )}
        </div>

      {/* Box 3: Leaderboard */}
      <div className="bg-white rounded-2xl shadow-md p-6">
        <h3 className="text-lg font-semibold text-center text-gray-700 mb-4">
          Leaderboard
        </h3>
        {/* Table Header */}
        {renderLeaderboard()}
      </div>
    </div>
  );
};

export default DashboardBoxes;

import React, { useState, useEffect, useCallback } from 'react';
import { Eye } from 'lucide-react';
import FeedbackModal from './FeedbackModal';

const FeedbackTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [filterStatus, setFilterStatus] = useState({
    all: true,
    checked: false,
    unchecked: false,
    issue: false,
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [feedbackData, setFeedbackData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    fetchFeedback();
  }, []);

  const fetchFeedback = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/view-feedback`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch feedback');
      }
  
      const data = await response.json();
      const formattedData = data.map((feedback, index) => ({
        id: index + 1,
        feedbackId: feedback._id,
        parentName: feedback.parentName,
        mobileNumber: feedback.parentPhoneNo,
        email: feedback.parentEmail,
        message: feedback.message,
        date: new Date(feedback.created_at).toLocaleDateString(),
        status: feedback.status,
      }));
  
      setFeedbackData(formattedData);
      setError(null);
    } catch (err) {
      setError('Failed to load feedback');
      console.error('Error fetching feedback:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  const filteredData = feedbackData.filter((row) => {
    const matchesSearchQuery =
      row.parentName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.mobileNumber?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.message?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.date?.toLowerCase().includes(searchQuery.toLowerCase());
  
    if (!matchesSearchQuery) return false;
  
    if (filterStatus.all) return true;
    if (filterStatus.checked && row.status === 'Checked') return true;
    if (filterStatus.unchecked && row.status === 'Unchecked') return true;
    if (filterStatus.issue && row.status === 'Issue') return true;
    
    return false;
  });
  const handleOpenModal = (feedback) => {
    setSelectedFeedback(feedback);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedFeedback(null);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFilterStatus((prevStatus) => ({
      ...prevStatus,
      [name]: checked,
      all: name === 'all' ? checked : prevStatus.all,
    }));
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };
  
  // Add sorting logic before table rendering
  const sortedData = [...filteredData].sort((a, b) => {
    if (!sortField) return 0;
  
    let aValue = a[sortField];
    let bValue = b[sortField];
  
    // Handle different field types
    switch (sortField) {
      case 'parentName':
      case 'mobileNumber':
      case 'email':
      case 'status':
        aValue = aValue?.toLowerCase() || '';
        bValue = bValue?.toLowerCase() || '';
        break;
      case 'date':
        aValue = new Date(aValue || 0).getTime();
        bValue = new Date(bValue || 0).getTime();
        break;
    }
  
    if (sortDirection === 'asc') {
      return aValue > bValue ? 1 : -1;
    } else {
      return aValue < bValue ? 1 : -1;
    }
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);


  const handlePageChange = (pageNumber) => {
    setCurrentPage(Math.min(Math.max(1, pageNumber), totalPages));
  };

  const handleLimitChange = (event) => {
    const newLimit = parseInt(event.target.value);
    setItemsPerPage(newLimit);
    setCurrentPage(1);
  };

  return (
    <>
      <div className="flex justify-between items-center mb-2 ml-4">
        <h1 className="text-2xl font-bold">Feedback</h1>
      </div>
      <p className="text-gray-500 text-sm ml-4">
        User Feedback <span className="text-gray-400">&gt; Feedback</span>
      </p>
      <div className="bg-white rounded-2xl shadow-md p-6 m-5">
        {/* Header */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-gray-700">Feedback</h2>
          <div className="flex items-center space-x-2">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
              className="border rounded px-2 py-1"
            />
            <button className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600">
              Filter
            </button>
          </div>
        </div>

        {/* Filters */}
        <div className="flex space-x-4 mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              name="all"
              checked={filterStatus.all}
              onChange={handleCheckboxChange}
              className="mr-2"
            />
            All
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="checked"
              checked={filterStatus.checked}
              onChange={handleCheckboxChange}
              className="mr-2"
            />
            Checked
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="unchecked"
              checked={filterStatus.unchecked}
              onChange={handleCheckboxChange}
              className="mr-2"
            />
            Unchecked
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="issue"
              checked={filterStatus.issue}
              onChange={handleCheckboxChange}
              className="mr-2"
            />
            Issue
          </label>
        </div>

        {/* Content */}
        {loading ? (
          <div className="text-center py-4">Loading feedback data...</div>
        ) : error ? (
          <div className="text-red-500 text-center py-4">Error: {error}</div>
        ) : feedbackData.length === 0 ? (
          <div className="text-center py-4">No feedback data found</div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full table-auto">
              {/* Table Head */}
              <thead>
                <tr className="bg-blue-100 text-blue-600">
                  <th className="px-4 py-2 text-left">#</th>
                  <th 
                    className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
                    onClick={() => handleSort('parentName')}
                  >
                    Parent's Name {sortField === 'parentName' && (sortDirection === 'asc' ? '↑' : '↓')}
                  </th>
                  <th 
                    className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
                    onClick={() => handleSort('mobileNumber')}
                  >
                    Mobile Number {sortField === 'mobileNumber' && (sortDirection === 'asc' ? '↑' : '↓')}
                  </th>
                  <th 
                    className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
                    onClick={() => handleSort('email')}
                  >
                    Email {sortField === 'email' && (sortDirection === 'asc' ? '↑' : '↓')}
                  </th>
                  <th className="px-4 py-2 text-left">Message</th>
                  <th 
                    className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
                    onClick={() => handleSort('date')}
                  >
                    Date {sortField === 'date' && (sortDirection === 'asc' ? '↑' : '↓')}
                  </th>
                  <th 
                    className="px-4 py-2 text-left cursor-pointer hover:bg-blue-200"
                    onClick={() => handleSort('status')}
                  >
                    Status {sortField === 'status' && (sortDirection === 'asc' ? '↑' : '↓')}
                  </th>
                  <th className="px-4 py-2 text-left">Action</th>
                </tr>
              </thead>

              {/* Table Body */}
              <tbody className="text-gray-600">
                {currentItems.map((row) => (
                  <tr key={row.feedbackId} className="border-b">
                    <td className="px-4 py-2">{row.id}</td>
                    <td className="px-4 py-2">{row.parentName}</td>
                    <td className="px-4 py-2">{row.mobileNumber}</td>
                    <td className="px-4 py-2">{row.email}</td>
                    <td className="px-4 py-2">{row.message}</td>
                    <td className="px-4 py-2">{row.date}</td>
                    <td className="px-4 py-2">{row.status}</td>
                    <td className="p-2">
                      <div className="flex space-x-2">
                        <Eye
                          size={16}
                          className="text-blue-500 cursor-pointer"
                          onClick={() => handleOpenModal(row)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex justify-between items-center mt-4">
              <div className="flex items-center space-x-2">
                <span>Show:</span>
                <select 
                  className="border rounded px-2 py-1"
                  value={itemsPerPage}
                  onChange={handleLimitChange}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={filteredData.length}>All</option>
                </select>
                <span>entries</span>
              </div>

              <div className="flex items-center space-x-2">
                <button 
                  className="border rounded px-3 py-1 hover:bg-gray-100"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  &lt;
                </button>

                {[...Array(totalPages)].map((_, index) => (
                  <button
                    key={index + 1}
                    className={`px-3 py-1 rounded ${
                      currentPage === index + 1 
                        ? 'bg-blue-500 text-white' 
                        : 'border hover:bg-gray-100'
                    }`}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </button>
                ))}

                <button 
                  className="border rounded px-3 py-1 hover:bg-gray-100"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  &gt;
                </button>
              </div>

              <div className="text-gray-600">
                Showing {indexOfFirstItem + .1} to {Math.min(indexOfLastItem, filteredData.length)} of {filteredData.length} entries
              </div>
            </div>
          </div>
        )}
        {isModalOpen && (
        <FeedbackModal 
          feedback={selectedFeedback} 
          onClose={handleCloseModal}
          onStatusUpdate={fetchFeedback} // Pass the fetch function
        />
      )}
      </div>
    </>
  );
};

export default FeedbackTable;
import React from 'react';

const ProductModal = ({ product, onClose }) => {
  if (!product) return null;

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleDateString();
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(price);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-2xl text-center font-semibold text-gray-800 w-full">
              {product.productName}
            </h3>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div className="space-y-4">
              <div className="aspect-square w-full max-w-[300px]">
                <img 
                  src={product.productCover} 
                  alt={product.productName} 
                  className="w-full h-full object-cover rounded-lg shadow-md"
                />
              </div>
              {product.productLogo && (
                <div className="w-24 h-24">
                  <img 
                    src={product.productLogo} 
                    alt="Product Logo" 
                    className="w-full h-full object-contain"
                  />
                </div>
              )}
            </div>
            
            <div className="space-y-3">
              <p className="text-sm text-gray-600">
                <strong>Product Type:</strong> {product.productType}
              </p>
              <p className="text-sm text-gray-600">
                <strong>Price:</strong> {formatPrice(product.productPrice)}
              </p>
              {product.totalPage && (
                <p className="text-sm text-gray-600">
                  <strong>Total Pages:</strong> {product.totalPage}
                </p>
              )}
              <p className="text-sm text-gray-600">
                <strong>Created Date:</strong> {formatDate(product.createdAt)}
              </p>
              <p className="text-sm text-gray-600">
                <strong>Launch Date:</strong> {formatDate(product.launchDate)}
              </p>
              <p className="text-sm text-gray-600">
                <strong>Activation Status:</strong>{' '}
                <span className={`px-2 py-1 rounded-full text-xs ${
                  product.activation > 0 ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'
                }`}>
                  {product.activation > 0 ? 'Active' : 'Inactive'}
                </span>
              </p>
            </div>
          </div>

          {product.about && (
            <>
              <h4 className="text-lg font-semibold mb-2">About</h4>
              <div className="border border-gray-200 rounded-lg p-4 bg-gray-50">
                <p className="text-sm text-gray-800 whitespace-pre-wrap">{product.about}</p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
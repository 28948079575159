import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Undo2 } from 'lucide-react';

const KidsProfileEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const kidData = location.state;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    name: kidData?.name || '',
    age: kidData?.age || '',
    gender: kidData?.gender || ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setError(null); // Clear error when user makes changes
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/edit-child/${kidData.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: formData.name,
          age: parseInt(formData.age),
          gender: formData.gender
        })
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to update kid profile');
      }

      // Success - navigate back
      navigate(-1);
    } catch (err) {
      setError(err.message);
      console.error('Error updating kid profile:', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBack = () => navigate(-1);

  return (
    <>
      <div className="flex justify-between items-center mb-2 ml-4">
        <h1 className="text-2xl font-bold">Edit Kids Profile</h1>
        <button 
          className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 mr-5"
          onClick={handleBack}
        >
          <Undo2 size={20} />
        </button>
      </div>
      <p className="text-gray-500 text-sm ml-4">
        Kids Management <span className="text-gray-400">&gt; Edit Kids Profile</span>
      </p>
      <div className="bg-white rounded-2xl shadow-md p-6 m-5">
        {error && (
          <div className="mb-4 p-3 bg-red-100 text-red-600 rounded-lg">
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-3 gap-4">
            <div className="flex flex-col">
              <label className="text-gray-700">Kid's Name</label>
              <input 
                type="text" 
                name="name" 
                value={formData.name} 
                onChange={handleChange} 
                placeholder="Enter Kid's Name"
                className="border rounded px-2 py-1"
                required
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700">Age</label>
              <input 
                type="number" 
                name="age" 
                value={formData.age} 
                onChange={handleChange} 
                placeholder="Enter Age"
                min="0"
                max="18"
                className="border rounded px-2 py-1"
                required
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700">Gender</label>
              <select 
                name="gender" 
                value={formData.gender} 
                onChange={handleChange}
                className="border rounded px-2 py-1"
                required
              >
                <option value="">Select Gender</option>
                <option value="Boy">Boy</option>
                <option value="Girl">Girl</option>
              </select>
            </div>
          </div>
          <div className="flex justify-end">
            <button 
              type="submit" 
              className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 disabled:bg-blue-300"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default KidsProfileEdit;